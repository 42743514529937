export const LOAD_ANALYSES = "LOAD_ANALYSES";
export const CREATE_ANALYSIS = "CREATE_ANALYSIS";
export const DELETE_ANALYSIS = "DELETE_ANALYSIS";
export const EDIT_ANALYSIS = "EDIT_ANALYSIS";
export const CLEAN_ANALYSES = "CLEAN_ANALYSES";
export const LOAD_ANALYSIS_TO_EDIT = "LOAD_ANALYSIS_TO_EDIT";
export const SET_ANALYSIS_TO_EDIT = "SET_ANALYSIS_TO_EDIT";

export const loadAnalyses = (payload) => {
  return {
    type: LOAD_ANALYSES,
    payload,
  };
};

export const loadAnalysisToEdit = (payload) => {
  return {
    type: LOAD_ANALYSIS_TO_EDIT,
    payload,
  };
};

export const setAnalysisToEdit = (payload) => {
  return {
    type: SET_ANALYSIS_TO_EDIT,
    payload,
  };
};

export const createAnalysis = (payload) => {
  return {
    type: CREATE_ANALYSIS,
    payload,
  };
};

export const deleteAnalysis = (payload) => {
  return {
    type: DELETE_ANALYSIS,
    payload,
  };
};

export const editAnalysis = (payload) => {
  return {
    type: EDIT_ANALYSIS,
    payload,
  };
};

export const cleanAnalyses = () => {
  return {
    type: CLEAN_ANALYSES,
  };
};
