import {
  LOAD_ACCESSES,
  CREATE_ACCESS,
  DELETE_ACCESS,
  EDIT_ACCESS,
  CLEAN_ACCESSES,
  LOAD_ACCESS_TO_EDIT,
  SET_ACCESS_TO_EDIT,
} from "../actions/accesses.action";

const initialState = {
  areAccessesLoaded: false,
  accessesList: [],
  organizationList: [],
  currentAccess: null,
};

const AccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACCESSES:
      return {
        areAccessesLoaded: true,
        accessesList: action.payload.projectUsers,
        organizationList: action.payload.OrganizationUsers,
        currentAccess: null,
      };

    case CREATE_ACCESS:
      if (!state.areAccessesLoaded) {
        return state;
      }
      const accesses = state.accessesList;
      accesses.push(action.payload);
      return { ...state, accessesList: accesses };

    case SET_ACCESS_TO_EDIT:
      return {
        ...state,
        currentAccess: action.payload,
      };

    case EDIT_ACCESS:
      if (!state.areAccessesLoaded) {
        return state;
      }
      return editAccess(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_ACCESS_TO_EDIT:
      if (!state.areAccessesLoaded) {
        return state;
      }
      return loadAccessToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_ACCESS:
      if (!state.areAccessesLoaded) {
        return state;
      }
      return deleteAccess(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_ACCESSES:
      return initialState;

    default:
      return state;
  }
};

const editAccess = (state, updatedAccess) => {
  const accesses = state.accessesList;
  const index = accesses.findIndex((a) => a.id === updatedAccess.id);
  accesses.splice(index, 1, updatedAccess);
  return { ...state, accessesList: accesses };
};

const deleteAccess = (state, accessID) => {
  const accesses = state.accessesList;
  const index = accesses.findIndex((a) => a.id === accessID);
  accesses.splice(index, 1);
  return { ...state, accessesList: accesses };
};

const loadAccessToEdit = (state, accessID) => {
  const accesses = state.accessesList;
  const selectedAccess = accesses.find((a) => a.id === accessID);
  return {
    ...state,
    currentAccess: selectedAccess,
  };
};

export default AccessReducer;
