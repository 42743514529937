import {
  LOAD_BUSINESSES,
  CREATE_BUSINESS,
  EDIT_BUSINESS,
  DELETE_BUSINESS,
  CLEAN_BUSINESSES,
  LOAD_BUSINESS_TO_EDIT,
  SET_BUSINESS_TO_EDIT,
} from "../actions/businesses.action";

const initialState = {
  areBusinessesLoaded: false,
  businessesList: [],
  currentBusiness: null,
};

const BusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUSINESSES:
      return {
        areBusinessesLoaded: true,
        businessesList: action.payload,
        currentBusiness: null,
      };

    case CREATE_BUSINESS:
      if (!state.areBusinessesLoaded) {
        return state;
      }
      const businesses = state.businessesList;
      businesses.push(action.payload);
      return { ...state, businessesList: businesses };

    case SET_BUSINESS_TO_EDIT:
      return {
        ...state,
        currentBusiness: action.payload,
      };

    case EDIT_BUSINESS:
      if (!state.areBusinessesLoaded) {
        return state;
      }
      return editBusiness(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_BUSINESS_TO_EDIT:
      if (!state.areBusinessesLoaded) {
        return state;
      }
      return loadBusinessToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_BUSINESS:
      if (!state.areBusinessesLoaded) {
        return state;
      }
      return deleteBusiness(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_BUSINESSES:
      return initialState;

    default:
      return state;
  }
};

const editBusiness = (state, updatedBusiness) => {
  const businesses = state.businessesList;
  const index = businesses.findIndex((b) => b.id === updatedBusiness.id);
  businesses.splice(index, 1, updatedBusiness);
  return { ...state, businessesList: businesses };
};

const deleteBusiness = (state, businessID) => {
  const businesses = state.businessesList;
  const index = businesses.findIndex((b) => b.id === businessID);
  businesses.splice(index, 1);
  return { ...state, businessesList: businesses };
};

const loadBusinessToEdit = (state, businessID) => {
  const businesses = state.businessesList;
  const selectedBUSINESS = businesses.find((b) => b.id === businessID);
  return {
    ...state,
    currentBusiness: selectedBUSINESS,
  };
};

export default BusinessReducer;
