const getTransactionId = () => {
  let ip = "0.0.0.1",
    seed = 1234567890;

  seed = ip.split(".").reduce(function (ip, octet, i) {
    return Number(ip) + Number(octet) * 255 * (i + 1);
  });

  let random = Math.sin(seed++) * 10000 - Math.floor(seed);
  random = Math.abs(random).toString().replace(".", "-");

  let milisec = new Date().getTime();

  return "trid-" + milisec + "-" + random;
};

export default getTransactionId;
