import AccessReducer from "./access.reducer";
import AnalysisReducer from "./analysis.reducer";
import AnalysisTypeReduder from "./analysisType.reducer";
import AuthReducer from "./auth.reducer";
import BusinessReducer from "./business.reducer";
import CategoryReducer from "./category.reducer";
import ClassificationReducer from "./classification.reducer";
import ColumnReducer from "./column.reducer";
import ConsequenceReducer from "./consequence.reducer";
import DeleteReducer from "./delete.reducer";
import ExposedReducer from "./exposed.reducer";
import GroupReducer from "./group.reducer";
import LanguageReducer from "./language.reducer";
import MatrixReducer from "./matrix.reducer";
import ModalReducer from "./modal.reducer";
import PasswordReducer from "./password.reducer";
import PermissionReducer from "./permission.reducer";
import PlayerReducer from "./player.reducer";
import ProbabilityReducer from "./probability.reducer";
import ProjectReducer from "./projects.reducer";
import SectorReducer from "./sector.reducer";
import SiteRecuder from "./site.reducer";
import StandbyReducer from "./standby.reducer";
import ThreatReducer from "./threat.reducer";
import TreatmentReducer from "./treatment.reducer";
import { combineReducers } from "redux";
import SelectWeighReducer from "./selectWeigh.reducer";
import ValidationReducer from "./validationModal.reducer";
import LocationReducer from "./location.reducer";

const rootReducer = combineReducers({
  language: LanguageReducer,
  auth: AuthReducer,
  project: ProjectReducer,
  group: GroupReducer,
  threat: ThreatReducer,
  exposed: ExposedReducer,
  site: SiteRecuder,
  matrix: MatrixReducer,
  column: ColumnReducer,
  player: PlayerReducer,
  password: PasswordReducer,
  modal: ModalReducer,
  business: BusinessReducer,
  standby: StandbyReducer,
  delete: DeleteReducer,
  consequence: ConsequenceReducer,
  classification: ClassificationReducer,
  treatment: TreatmentReducer,
  probability: ProbabilityReducer,
  access: AccessReducer,
  permission: PermissionReducer,
  sector: SectorReducer,
  analysis_type: AnalysisTypeReduder,
  category: CategoryReducer,
  analysis: AnalysisReducer,
  selectWeigh: SelectWeighReducer,
  validation: ValidationReducer,
  location: LocationReducer,
});

export default rootReducer;
