/* CLASSIFICATIONS */
export const LOAD_MATRIX_CLASSIFICATIONS = "LOAD_MATRIX_CLASSIFICATIONS";
export const EDIT_MATRIX_CLASSIFICATION = "EDIT_MATRIX_CLASSIFICATION";
export const SET_MATRIX_CLASSIFICATION_TO_EDIT =
  "SET_MATRIX_CLASSIFICATION_TO_EDIT";
export const CLEAN_MATRIX_CLASSIFICATIONS = "CLEAN_MATRIX_CLASSIFICATIONS";

export const loadMatrixClassifications = (payload) => {
  return {
    type: LOAD_MATRIX_CLASSIFICATIONS,
    payload,
  };
};

export const editMatrixClassification = (payload) => {
  return {
    type: EDIT_MATRIX_CLASSIFICATION,
    payload,
  };
};

export const setMatrixClassificationToEdit = (payload) => {
  return {
    type: SET_MATRIX_CLASSIFICATION_TO_EDIT,
    payload,
  };
};

export const cleanMatrixClassifications = () => {
  return {
    type: CLEAN_MATRIX_CLASSIFICATIONS,
  };
};
