/* COLUMNS */
export const LOAD_MATRIX_COLUMNS = "LOAD_MATRIX_COLUMNS";
export const CREATE_MATRIX_COLUMN = "CREATE_MATRIX_COLUMN";
export const DELETE_MATRIX_COLUMN = "DELETE_MATRIX_COLUMN";
export const EDIT_MATRIX_COLUMN = "EDIT_MATRIX_COLUMN";
export const SET_MATRIX_COLUMN_TO_EDIT = "SET_MATRIX_COLUMN_TO_EDIT";
export const CLEAN_MATRIX_COLUMNS = "CLEAN_MATRIX_COLUMNS";

export const loadMatrixColumns = (payload) => {
  return {
    type: LOAD_MATRIX_COLUMNS,
    payload,
  };
};

export const addMatrixColumn = (payload) => {
  return {
    type: CREATE_MATRIX_COLUMN,
    payload,
  };
};

export const deleteMatrixColumn = (payload) => {
  return {
    type: DELETE_MATRIX_COLUMN,
    payload,
  };
};

export const editMatrixColumn = (payload) => {
  return {
    type: EDIT_MATRIX_COLUMN,
    payload,
  };
};

export const setMatrixColumnToEdit = (payload) => {
  return {
    type: SET_MATRIX_COLUMN_TO_EDIT,
    payload,
  };
};

export const cleanMatrixColumns = () => {
  return {
    type: CLEAN_MATRIX_COLUMNS,
  };
};
