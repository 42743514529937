import {
  LOAD_PROJECT_LIST,
  LOAD_PROJECT_TO_EDIT,
  SET_PROJECT_TO_EDIT,
  CREATE_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  CLEAN_PROJECT_REDUCER,
} from "../actions/projects.action";

const initialState = {
  areProjectsLoaded: false,
  projectList: [],
  currentProject: null,
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROJECT_LIST:
      return {
        ...state,
        areProjectsLoaded: true,
        projectList: action.payload,
        currentProject: null,
      };

    case SET_PROJECT_TO_EDIT:
      return {
        ...state,
        currentProject: action.payload,
      };

    case EDIT_PROJECT:
      if (!state.areProjectsLoaded) {
        return state;
      }
      return editProject(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_PROJECT_TO_EDIT:
      if (!state.areProjectsLoaded) {
        return state;
      }
      return loadProjectToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_PROJECT:
      if (!state.areProjectsLoaded) {
        return state;
      }
      return deleteProject(JSON.parse(JSON.stringify(state)), action.payload);

    case CREATE_PROJECT:
      if (!state.areProjectsLoaded) {
        return state;
      }
      const projects = state.projectList;
      projects.push(action.payload);
      return { ...state, projectList: projects };

    case CLEAN_PROJECT_REDUCER:
      return initialState;

    default:
      return state;
  }
};

const deleteProject = (state, projectId) => {
  const projects = state.projectList;
  const index = projects.findIndex((p) => p.id === projectId);
  projects.splice(index, 1);
  return { ...state, projectList: projects };
};

const loadProjectToEdit = (state, projectId) => {
  const projects = state.projectList;
  const selectedProject = projects.find((p) => p.id === projectId);
  return {
    ...state,
    currentProject: selectedProject,
  };
};

const editProject = (state, updatedProject) => {
  const projects = state.projectList;
  const index = projects.findIndex((p) => p.id === updatedProject.id);
  projects.splice(index, 1, updatedProject);
  return { ...state, projectList: projects };
};

export default ProjectReducer;
