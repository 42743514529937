export const USER_LOGIN = "USER_LOGIN";

export const USER_LOGOUT = "USER_LOGOUT";

export const SET_PROFILE = "SET_PROFILE";

export const REMOVE_PROFILE = "REMOVE_PROFILE";

export const userLogIn = () => {
  return {
    type: USER_LOGIN,
  };
};

export const userLogOut = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const setProfile = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

export const removeProfile = () => {
  return {
    type: REMOVE_PROFILE,
  };
};
