/* PROBABILITIES */
export const LOAD_MATRIX_PROBABILITY_CRITERIAS =
  "LOAD_MATRIX_PROBABILITY_CRITERIAS";
export const LOAD_MATRIX_PROBABILITY_LEVELS = "LOAD_MATRIX_PROBABILITY_LEVELS";
export const EDIT_MATRIX_PROBABILITY_CRITERIA =
  "EDIT_MATRIX_PROBABILITY_CRITERIA";
export const EDIT_MATRIX_PROBABILITY_LEVELS = "EDIT_MATRIX_PROBABILITY_LEVELS";
export const SET_MATRIX_PROBABILITY_TO_EDIT = "SET_MATRIX_PROBABILITY_TO_EDIT";
export const CLEAN_MATRIX_PROBABILITIES = "CLEAN_MATRIX_PROBABILITIES";

export const loadMatrixProbabilityCriterias = (payload) => {
  return {
    type: LOAD_MATRIX_PROBABILITY_CRITERIAS,
    payload,
  };
};

export const loadMatrixProbabilityLevels = (payload) => {
  return {
    type: LOAD_MATRIX_PROBABILITY_LEVELS,
    payload,
  };
};

export const editMatrixProbabilityCriteria = (payload) => {
  return {
    type: EDIT_MATRIX_PROBABILITY_CRITERIA,
    payload,
  };
};

export const editMatrixProbabilityLevels = (payload) => {
  return {
    type: EDIT_MATRIX_PROBABILITY_LEVELS,
    payload,
  };
};

export const setMatrixProbabilityToEdit = (payload) => {
  return {
    type: SET_MATRIX_PROBABILITY_TO_EDIT,
    payload,
  };
};

export const cleanMatrixProbabilities = () => {
  return {
    type: CLEAN_MATRIX_PROBABILITIES,
  };
};
