export const LOAD_ANALYSIS_TYPES = "LOAD_ANALYSIS_TYPES";
export const CREATE_ANALYSIS_TYPE = "CREATE_ANALYSIS_TYPE";
export const DELETE_ANALYSIS_TYPE = "DELETE_ANALYSIS_TYPE";
export const EDIT_ANALYSIS_TYPE = "EDIT_ANALYSIS_TYPE";
export const CLEAN_ANALYSIS_TYPES = "CLEAN_ANALYSIS_TYPES";
export const LOAD_ANALYSIS_TYPE_TO_EDIT = "LOAD_ANALYSIS_TYPE_TO_EDIT";
export const SET_ANALYSIS_TYPE_TO_EDIT = "SET_ANALYSIS_TYPE_TO_EDIT";

export const loadAnalysisTypes = (payload) => {
  return {
    type: LOAD_ANALYSIS_TYPES,
    payload,
  };
};

export const loadAnalysisTypeToEdit = (payload) => {
  return {
    type: LOAD_ANALYSIS_TYPE_TO_EDIT,
    payload,
  };
};

export const setAnalysisTypeToEdit = (payload) => {
  return {
    type: SET_ANALYSIS_TYPE_TO_EDIT,
    payload,
  };
};

export const createAnalysisType = (payload) => {
  return {
    type: CREATE_ANALYSIS_TYPE,
    payload,
  };
};

export const deleteAnalysisType = (payload) => {
  return {
    type: DELETE_ANALYSIS_TYPE,
    payload,
  };
};

export const editAnalysisType = (payload) => {
  return {
    type: EDIT_ANALYSIS_TYPE,
    payload,
  };
};

export const cleanAnalysisTypes = () => {
  return {
    type: CLEAN_ANALYSIS_TYPES,
  };
};
