import axios from "axios";

const baseDomain = process.env.REACT_APP_API_AUTH;

const conn = axios.create({
  baseURL: baseDomain,
  timeout: 10000,
});

conn.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response)
);

export default conn;
