const USER_PROFILE = "profile";

export const get = (key) => {
  return localStorage.getItem(key);
};

export const set = (key, value) => {
  return localStorage.setItem(key, value);
};

export const remove = (key) => {
  return localStorage.removeItem(key);
};

export const encrypt = (key, value) => {
  return set(key, window.btoa(value));
};

export const decrypt = (key) => {
  if (get(key) !== null) {
    return window.atob(get(key));
  }
};

export const saveProfile = (profile) => {
  encrypt(USER_PROFILE, JSON.stringify(profile));
};

export const getProfile = () => {
  return JSON.parse(decrypt(USER_PROFILE));
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  set,
  remove,
  encrypt,
  decrypt,
  saveProfile,
  getProfile,
};
