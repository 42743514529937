import {
  LOAD_THREATS,
  CREATE_THREAT,
  EDIT_THREAT,
  DELETE_THREAT,
  CLEAN_THREATS,
  LOAD_THREAT_TO_EDIT,
  SET_THREAT_TO_EDIT,
  LOAD_THREAT_EXPOSEDS,
  ADD_THREAT_EXPOSED,
  REMOVE_THREAT_EXPOSED,
  LOAD_THREATS_BY_GROUP,
} from "../actions/threats.action";

const initialState = {
  areThreatsLoaded: false,
  threatList: [],
  threatByGroupList: [],
  currentThreat: null,
  threatExposedList: [],
};

const ThreatReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_THREATS:
      return {
        ...initialState,
        areThreatsLoaded: true,
        threatList: action.payload,
      };

    case LOAD_THREATS_BY_GROUP:
      return {
        ...state,
        areThreatsLoaded: true,
        threatByGroupList: action.payload,
      };

    case LOAD_THREAT_EXPOSEDS:
      return {
        ...state,
        threatExposedList: action.payload,
      };

    case ADD_THREAT_EXPOSED:
      return addThreatExposed(state, action.payload);

    case REMOVE_THREAT_EXPOSED:
      return removeThreatExposed(state, action.payload);

    case CREATE_THREAT:
      if (!state.areThreatsLoaded) {
        return state;
      }
      const threats = state.threatList;
      threats.push(action.payload);
      return { ...state, threatList: threats };

    case SET_THREAT_TO_EDIT:
      return {
        ...state,
        currentThreat: action.payload,
      };

    case EDIT_THREAT:
      if (!state.areThreatsLoaded) {
        return state;
      }
      return editThreat(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_THREAT_TO_EDIT:
      if (!state.areThreatsLoaded) {
        return state;
      }
      return loadThreatToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_THREAT:
      if (!state.areThreatsLoaded) {
        return state;
      }
      return deleteThreat(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_THREATS:
      return initialState;

    default:
      return state;
  }
};

const addThreatExposed = (state, exposed) => {
  const exposeds = state.threatExposedList.slice();
  exposeds.push(exposed);
  return {
    ...state,
    threatExposedList: exposeds,
  };
};

const editThreat = (state, updatedThreat) => {
  const threats = state.threatList;
  const index = threats.findIndex((g) => g.id === updatedThreat.id);
  threats.splice(index, 1, updatedThreat);
  return { ...state, threatList: threats };
};

const removeThreatExposed = (state, exposedID) => {
  const exposeds = state.threatExposedList;
  const index = exposeds.findIndex((ex) => ex.id === exposedID);
  exposeds.splice(index, 1);
  return {
    ...state,
    threatExposedList: exposeds,
  };
};

const deleteThreat = (state, threatId) => {
  const threats = state.threatList;
  const index = threats.findIndex((g) => g.id === threatId);
  threats.splice(index, 1);
  return { ...state, threatList: threats };
};

const loadThreatToEdit = (state, threatId) => {
  const threats = state.threatList;
  const selectedThreat = threats.find((g) => g.id === threatId);
  return {
    ...state,
    currentThreat: selectedThreat,
  };
};

export default ThreatReducer;
