import {
  LOAD_CATEGORIES,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  CLEAN_CATEGORIES,
  LOAD_CATEGORY_TO_EDIT,
  SET_CATEGORY_TO_EDIT,
} from "../actions/categories.action";

const initialState = {
  areCategoriesLoaded: false,
  categoryList: [],
  currentCategory: null,
};

const CategoryRepository = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return {
        areCategoriesLoaded: true,
        categoryList: action.payload,
        currentCategory: null,
      };

    case CREATE_CATEGORY:
      if (!state.areCategoriesLoaded) {
        return state;
      }
      const categories = state.categoryList;
      categories.push(action.payload);
      return { ...state, categoryList: categories };

    case SET_CATEGORY_TO_EDIT:
      return {
        ...state,
        currentCategory: action.payload,
      };

    case EDIT_CATEGORY:
      if (!state.areCategoriesLoaded) {
        return state;
      }
      return editCategory(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_CATEGORY_TO_EDIT:
      if (!state.areCategoriesLoaded) {
        return state;
      }
      return loadCategoryToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_CATEGORY:
      if (!state.areCategoriesLoaded) {
        return state;
      }
      return deleteCategory(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_CATEGORIES:
      return initialState;

    default:
      return state;
  }
};

const editCategory = (state, updatedCategory) => {
  const categories = state.categoryList;
  const index = categories.findIndex((c) => c.id === updatedCategory.id);
  categories.splice(index, 1, updatedCategory);
  return { ...state, categoryList: categories };
};

const deleteCategory = (state, categoryID) => {
  const categories = state.categoryList;
  const index = categories.findIndex((c) => c.id === categoryID);
  categories.splice(index, 1);
  return { ...state, categoryList: categories };
};

const loadCategoryToEdit = (state, categoryID) => {
  const categories = state.categoryList;
  const selectecCategory = categories.find((c) => c.id === categoryID);
  return {
    ...state,
    currentCategory: selectecCategory,
  };
};

export default CategoryRepository;
