import {
  LOAD_SECTORS,
  CREATE_SECTOR,
  EDIT_SECTOR,
  DELETE_SECTOR,
  CLEAN_SECTORS,
  LOAD_SECTOR_TO_EDIT,
  SET_SECTOR_TO_EDIT,
} from "../actions/sector.action";

const initialState = {
  areSectorsLoaded: false,
  sectorList: [],
  currentSector: null,
};

const SectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SECTORS:
      return {
        areSectorsLoaded: true,
        sectorList: action.payload,
        currentSector: null,
      };

    case CREATE_SECTOR:
      if (!state.areSectorsLoaded) {
        return state;
      }
      const sectors = state.sectorList;
      sectors.push(action.payload);
      return { ...state, sectorList: sectors };

    case SET_SECTOR_TO_EDIT:
      return {
        ...state,
        currentSector: action.payload,
      };

    case EDIT_SECTOR:
      if (!state.areSectorsLoaded) {
        return state;
      }
      return editSector(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_SECTOR_TO_EDIT:
      if (!state.areSectorsLoaded) {
        return state;
      }
      return loadSectorToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_SECTOR:
      if (!state.areSectorsLoaded) {
        return state;
      }
      return deleteSector(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_SECTORS:
      return initialState;

    default:
      return state;
  }
};

const editSector = (state, updatedSector) => {
  const sectors = state.sectorList;
  const index = sectors.findIndex((s) => s.id === updatedSector.id);
  sectors.splice(index, 1, updatedSector);
  return { ...state, sectorList: sectors };
};

const deleteSector = (state, sectorID) => {
  const sectors = state.sectorList;
  const index = sectors.findIndex((s) => s.id === sectorID);
  sectors.splice(index, 1);
  return { ...state, sectorList: sectors };
};

const loadSectorToEdit = (state, sectorID) => {
  const sectors = state.sectorList;
  const selectedSector = sectors.find((s) => s.id === sectorID);
  return {
    ...state,
    currentSector: selectedSector,
  };
};

export default SectorReducer;
