import {
  PERMISSION_SETUSER,
  CLEAN_PERMISSION,
} from "../actions/permissions.action";

const initialState = {
  lastPermissionAnalysis: null,
  userPermission: "PROJECT_NULL",
};

const PermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_SETUSER:
      return {
        ...state,
        lastPermissionAnalysis: action.payload.project,
        userPermission: action.payload.role,
      };

    case CLEAN_PERMISSION:
      return initialState;

    default:
      return state;
  }
};

export default PermissionReducer;
