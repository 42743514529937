import localstore from "./localstorage";
import AuthRepository from "../services/repositories/authorize.repository";

const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const USER_PROFILE = "profile";
const USER_LANGUAGES = "innerLanguages";
const CURRENT_USER_LANGUAGE = "currentInnerLanguage";

const decodeToken = (token) => {
  return JSON.parse(atob(token.split(".")[1]));
};

export const getToken = () => {
  return localstore.get(ID_TOKEN_KEY);
};

export const getCurrentInnerLanguage = () => {
  return localstore.get(CURRENT_USER_LANGUAGE);
};

export const startRefreshTokenLoop = () => {
  const timeoutTime =
    decodeToken(getToken()).exp * 1000 - new Date().getTime() - 300000;
  setTimeout(refreshTokenLoop, timeoutTime);
};

const refreshTokenLoop = async () => {
  const old_token = getToken();
  const old_refresh_token = getRefreshToken();

  if (isActiveToken(old_token) && old_refresh_token) {
    try {
      const { token, refresh_token } = await AuthRepository.refreshToken(
        old_refresh_token
      );
      const timeoutTime =
        decodeToken(token).exp * 1000 - new Date().getTime() - 300000;
      saveToken(token);
      saveRefreshToken(refresh_token);
      setTimeout(refreshTokenLoop, timeoutTime);
    } catch (e) {
      console.log(e);
      setTimeout(refreshTokenLoop, 60000);
    }
  } else {
    checkStaoragedToken();
  }
};

export const checkStaoragedToken = () => {
  const token = getToken();
  if (isActiveToken(token)) {
    return refreshTokenLoop();
  } else {
    return cleanUserData();
  }
};

export const isActiveToken = (token) => {
  try {
    if (token) {
      const decoded_token = decodeToken(token);
      const current_timestamp = new Date().getTime();
      if (current_timestamp < decoded_token.exp * 1000 - 60000) {
        return true;
      }
    }
  } catch (e) {
    console.log(e);
    return false;
  }
  return false;
};

export const getRefreshToken = () => {
  return localstore.get(REFRESH_TOKEN_KEY);
};

export const saveToken = (token) => {
  localstore.set(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = (token) => {
  localstore.set(REFRESH_TOKEN_KEY, token);
};

export const destroyToken = () => {
  localstore.remove(ID_TOKEN_KEY);
};

export const destroyRefreshToken = () => {
  localstore.remove(REFRESH_TOKEN_KEY);
};

export const cleanUserData = () => {
  localstore.remove(ID_TOKEN_KEY);
  localstore.remove(REFRESH_TOKEN_KEY);
  localstore.remove(USER_PROFILE);
  localstore.remove(USER_LANGUAGES);
  localstore.remove(CURRENT_USER_LANGUAGE);
};

export const setAuthHeaderToken = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getAuthHeaderToken = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
      "X-LOCALE": getCurrentInnerLanguage(),
    },
  };
};

export const getAuthHeaderTokenForPatch = () => {
  return {
    headers: {
      "Content-Type": "application/merge-patch+json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
      "X-LOCALE": getCurrentInnerLanguage(),
    },
  };
};

export const getAuthHeaderTokenForPost = () => {
  return {
    headers: {
      "Content-Type": "application/ld+json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
      "X-LOCALE": getCurrentInnerLanguage(),
    },
  };
};

export const getAuthHeaderTokenForValidate = () => {
  return {
    headers: {
      "Content-Type": "application/ld+json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getToken,
  isActiveToken,
  getRefreshToken,
  saveToken,
  saveRefreshToken,
  destroyToken,
  destroyRefreshToken,
  getAuthHeaderToken,
  setAuthHeaderToken,
  getAuthHeaderTokenForPatch,
  getAuthHeaderTokenForPost,
  getAuthHeaderTokenForValidate,
  cleanUserData,
  startRefreshTokenLoop,
  checkStaoragedToken,
};
