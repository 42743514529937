export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const SET_ANALYSIS_DICTIONARY = "SET_ANALYSIS_DICTIONARY";
export const SWITCH_TO_ANALYSIS_DICTIONARY = "SWITCH_TO_ANALYSIS_DICTIONARY";
export const SWITCH_TO_BASE_DICTIONARY = "SWITCH_TO_BASE_DICTIONARY";
export const EDIT_KEY_ON_ANALYSIS_DICTIONARY =
  "EDIT_KEY_ON_ANALYSIS_DICTIONARY";
export const SWTICH_INNER_LANGUAGES = "SWTICH_INNER_LANGUAGES";
export const SET_CURRENT_INNER_LANGUAGE = "SET_CURRENT_INNER_LANGUAGE";

export const switchLanguage = (payload) => {
  return {
    type: SWITCH_LANGUAGE,
    payload,
  };
};

export const setAnalysisDictionary = (payload) => {
  return {
    type: SET_ANALYSIS_DICTIONARY,
    payload,
  };
};

export const switchToBaseDictionary = () => {
  return {
    type: SWITCH_TO_BASE_DICTIONARY,
  };
};

export const switchToAnalysisDictionary = () => {
  return {
    type: SWITCH_TO_ANALYSIS_DICTIONARY,
  };
};

export const editKeyOnAnalysisDictionary = (payload) => {
  return {
    type: EDIT_KEY_ON_ANALYSIS_DICTIONARY,
    payload,
  };
};

export const switchInnerLanguages = (payload) => {
  return {
    type: SWTICH_INNER_LANGUAGES,
    payload,
  };
};

export const setCurrentInnerLanguage = (payload) => {
  return {
    type: SET_CURRENT_INNER_LANGUAGE,
    payload,
  };
};
