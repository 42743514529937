export const LOAD_SECTORS = "LOAD_SECTORS";
export const CREATE_SECTOR = "CREATE_SECTOR";
export const DELETE_SECTOR = "DELETE_SECTOR";
export const EDIT_SECTOR = "EDIT_SECTOR";
export const CLEAN_SECTORS = "CLEAN_SECTORS";
export const LOAD_SECTOR_TO_EDIT = "LOAD_SECTOR_TO_EDIT";
export const SET_SECTOR_TO_EDIT = "SET_SECTOR_TO_EDIT";

export const loadSectors = (payload) => {
  return {
    type: LOAD_SECTORS,
    payload,
  };
};

export const loadSectorToEdit = (payload) => {
  return {
    type: LOAD_SECTOR_TO_EDIT,
    payload,
  };
};

export const setSectorToEdit = (payload) => {
  return {
    type: SET_SECTOR_TO_EDIT,
    payload,
  };
};

export const createSector = (payload) => {
  return {
    type: CREATE_SECTOR,
    payload,
  };
};

export const deleteSector = (payload) => {
  return {
    type: DELETE_SECTOR,
    payload,
  };
};

export const editSector = (payload) => {
  return {
    type: EDIT_SECTOR,
    payload,
  };
};

export const cleanSectors = () => {
  return {
    type: CLEAN_SECTORS,
  };
};
