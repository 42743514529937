import {
  LOAD_EXPOSEDS,
  CREATE_EXPOSED,
  EDIT_EXPOSED,
  DELETE_EXPOSED,
  CLEAN_EXPOSEDS,
  LOAD_EXPOSED_TO_EDIT,
  SET_EXPOSED_TO_EDIT,
} from "../actions/exposeds.action";

const initialState = {
  areExposedsLoaded: false,
  exposedList: [],
  currentExposed: null,
};

const ExposedReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EXPOSEDS:
      return {
        areExposedsLoaded: true,
        exposedList: action.payload,
        currentExposed: null,
      };

    case CREATE_EXPOSED:
      if (!state.areExposedsLoaded) {
        return state;
      }
      const exposeds = state.exposedList;
      exposeds.push(action.payload);
      return { ...state, exposedList: exposeds };

    case SET_EXPOSED_TO_EDIT:
      return {
        ...state,
        currentExposed: action.payload,
      };

    case EDIT_EXPOSED:
      if (!state.areExposedsLoaded) {
        return state;
      }
      return editExposed(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_EXPOSED_TO_EDIT:
      if (!state.areExposedsLoaded) {
        return state;
      }
      return loadExposedToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_EXPOSED:
      if (!state.areExposedsLoaded) {
        return state;
      }
      return deleteExposed(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_EXPOSEDS:
      return initialState;

    default:
      return state;
  }
};

const editExposed = (state, upatedExposed) => {
  const exposeds = state.exposedList;
  const index = exposeds.findIndex((g) => g.id === upatedExposed.id);
  exposeds.splice(index, 1, upatedExposed);
  return { ...state, exposedList: exposeds };
};

const deleteExposed = (state, exposedId) => {
  const exposeds = state.exposedList;
  const index = exposeds.findIndex((g) => g.id === exposedId);
  exposeds.splice(index, 1);
  return { ...state, exposedList: exposeds };
};

const loadExposedToEdit = (state, exposedId) => {
  const exposeds = state.exposedList;
  const selectedExposed = exposeds.find((g) => g.id === exposedId);
  return {
    ...state,
    currentExposed: selectedExposed,
  };
};

export default ExposedReducer;
