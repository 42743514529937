const navigationRouter = {
  HOME: "/app",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  SUPPORT: "/support",
  NOT_FOUND: "/404",
  AUTHCHECK: "/authcheck",
  ERROR: "/error",

  APP: "/riskapp",
  CREATE_ANALYSIS: "/projects/:id/analyses/new",
  EDIT_ANALYSIS: "/projects/:id/analyses/edit/:id2",
  DASHBOARD_ANALYSIS: "/projects/:id/analyses/:id2",

  LIST_PROJECTS: "/projects",
  CREATE_PROJECT: "/projects/new",
  EDIT_PROJECT: "/projects/edit/:id",

  TRANSLATION_EDIT: "/projects/:id/analyses/:id2/translations/edit",

  LIST_ACCESS: "/projects/:id/analyses/:id2/access",
  CREATE_ACCESS: "/projects/:id/analyses/:id2/access/new",
  EDIT_ACCESS: "/projects/:id/analyses/:id2/access/edit/:id3",

  CREATE_GROUP: "/projects/:id/analyses/:id2/groups/new",
  EDIT_GROUP: "/projects/:id/analyses/:id2/groups/edit/:id3",

  CREATE_SECTOR: "/projects/:id/sectors/new",
  EDIT_SECTOR: "/projects/:id/sectors/edit/:id2",

  CREATE_CATEGORY: "/projects/:id/categories/new",
  EDIT_CATEGORY: "/projects/:id/categories/edit/:id2",

  CREATE_TYPE_ANALYSIS: "/projects/:id/analysis_types/new",
  EDIT_TYPE_ANALYSIS: "/projects/:id/analysis_types/edit/:id2",

  CREATE_THREAT: "/projects/:id/analyses/:id2/threats/new",
  EDIT_THREAT: "/projects/:id/analyses/:id2/threats/edit/:id3",

  CREATE_BUSSINESS: "/projects/:id/analyses/:id2/businesses/new",
  EDIT_BUSSINESS: "/projects/:id/analyses/:id2/businesses/edit/:id3",

  CREATE_SITE: "/projects/:id/analyses/:id2/sites/new",
  EDIT_SITE: "/projects/:id/analyses/:id2/sites/edit/:id3",

  CREATE_PLAYER: "/projects/:id/analyses/:id2/sites/:id3/players/new",
  EDIT_PLAYER: "/projects/:id/analyses/:id2/sites/:id3/players/edit/:id4",

  PARAM_OPT_ZERO: "?option=0",
  PARAM_OPT_ONE: "?option=1",
  PARAM_OPT_TWO: "?option=2",
  PARAM_OPT_THREE: "?option=3",
  PARAM_OPT_FOUR: "?option=4",
  PARAM_OPT_FIVE: "?option=5",
  PARAM_OPT_SIX: "?option=6",
  PARAM_OPT_SEVEN: "?option=7",
  PARAM_OPT_EIGHT: "?option=8",
  PARAM_OPT_NINE: "?option=9",
  PARAM_OPT_TEN: "?option=10",
  PARAM_RESOURCE: "&resource={id}",
  PARAM_ELEMENT: "&element={id}",
  PARAM_TYPE: "type={id}",

  MATRIX_RISK: "/projects/:id/analyses/:id2/matrices",
  WEIGHING_RISK: "/projects/:id/analyses/:id2/weighing",
  VISUALIZATION_RISK: "/projects/:id/analyses/:id2/visualization",
  CREATE_COLUMN: "/projects/:id/analyses/:id2/matrices/:id3/columns/new",
  EDIT_COLUMN: "/projects/:id/analyses/:id2/matrices/columns/edit/:id3",
  EDIT_CONSEQUENCE:
    "/projects/:id/analyses/:id2/matrices/consequences/edit/:id3",
  EDIT_CLASSIFICATION:
    "/projects/:id/analyses/:id2/matrices/classifications/edit/:id3",
  CREATE_TREATMENT: "/projects/:id/analyses/:id2/matrices/:id3/treatments/new",
  EDIT_TREATMENT: "/projects/:id/analyses/:id2/matrices/treatments/edit/:id3",
  EDIT_PROBABILITY_CRITERIA:
    "/projects/:id/analyses/:id2/matrices/probabilities/criterias/edit/:id3",
  EDIT_PROBABILITY_LEVEL:
    "/projects/:id/analyses/:id2/matrices/probabilities/levels/edit/:id3",
};

export default navigationRouter;
