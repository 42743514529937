import {
  /* CONSEQUENCES */
  LOAD_MATRIX_CONSEQUENCES,
  SET_MATRIX_CONSEQUENCE_TO_EDIT,
  EDIT_MATRIX_CONSEQUENCE,
  CLEAN_MATRIX_CONSEQUENCES,
} from "../actions/consequences.action";

const initialState = {
  consequenceList: [],
  currentConsequence: null,
};

const ConsequenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MATRIX_CONSEQUENCES:
      return {
        consequenceList: action.payload,
        currentConsequence: null,
      };

    case SET_MATRIX_CONSEQUENCE_TO_EDIT:
      state.currentConsequence = action.payload;
      return state;

    case EDIT_MATRIX_CONSEQUENCE:
      return editConsequence(state, action.payload);

    case CLEAN_MATRIX_CONSEQUENCES:
      return initialState;

    default:
      return state;
  }
};

const editConsequence = (state, updatedConsequence) => {
  const consequences = state.consequenceList;
  const index = consequences.findIndex((c) => c.id === updatedConsequence.id);
  consequences.splice(index, 1, updatedConsequence);
  return {
    ...state,
    consequenceList: consequences,
  };
};

export default ConsequenceReducer;
