import {
  /* TREATMENTS */
  LOAD_MATRIX_TREATMENTS,
  CREATE_MATRIX_TREATMENT,
  SET_MATRIX_TREATMENT_TO_EDIT,
  DELETE_MATRIX_TREATMENT,
  EDIT_MATRIX_TREATMENT,
  CLEAN_MATRIX_TREATMENTS,
} from "../actions/treatments.action";

const initialState = {
  treatmentList: [],
  currentTreatment: null,
};

const TreatmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MATRIX_TREATMENTS:
      const sortedTreatments = action.payload.sort((a, b) =>
        a.upperLevel < b.upperLevel ? 1 : -1
      );
      return {
        treatmentList: sortedTreatments,
        currentTreatment: null,
      };
    case CREATE_MATRIX_TREATMENT:
      state.treatmentList.push(action.payload);
      state.treatmentList.sort((a, b) =>
        a.upperLevel < b.upperLevel ? 1 : -1
      );
      return state;

    case SET_MATRIX_TREATMENT_TO_EDIT:
      return { ...state, currentTreatment: action.payload };

    case DELETE_MATRIX_TREATMENT:
      return deleteTreatment(state, action.payload);

    case EDIT_MATRIX_TREATMENT:
      return editTreatment(state, action.payload);

    case CLEAN_MATRIX_TREATMENTS:
      return initialState;

    default:
      return state;
  }
};

const deleteTreatment = (state, treatmentID) => {
  const treatments = state.treatmentList;
  const index = treatments.findIndex((t) => t.id === treatmentID);
  treatments.splice(index, 1);
  return {
    ...state,
    treatmentList: treatments,
  };
};

const editTreatment = (state, updatedTreatment) => {
  const treatments = state.treatmentList;
  const index = treatments.findIndex((t) => t.id === updatedTreatment.id);
  treatments.splice(index, 1, updatedTreatment);
  return {
    ...state,
    treatmentList: treatments,
  };
};

export default TreatmentReducer;
