import {
  RESET_PASSWORD,
  EMAIL_STEP_SUCCESS,
  CODE_STEP_SUCCESS,
  PASSWORD_STEP_SUCCESS,
  SUCCESS_STEP_SUCCESS,
} from "../actions/password.action";

const initialState = {
  email: null,
  secret: null,
  password: null,
  stepEmail: true,
  stepCode: false,
  stepPassword: false,
  stepSuccess: false,
};

const offSteps = {
  stepEmail: false,
  stepCode: false,
  stepPassword: false,
  stepSuccess: false,
};

const PasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return initialState;

    case EMAIL_STEP_SUCCESS:
      return {
        ...state,
        ...offSteps,
        stepCode: true,
        email: action.payload,
      };

    case CODE_STEP_SUCCESS:
      return {
        ...state,
        ...offSteps,
        stepPassword: true,
        secret: action.payload,
      };

    case PASSWORD_STEP_SUCCESS:
      return {
        ...state,
        ...offSteps,
        stepSuccess: true,
        password: action.payload,
      };

    case SUCCESS_STEP_SUCCESS:
      return initialState;

    default:
      return initialState;
  }
};

export default PasswordReducer;
