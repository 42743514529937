export const SET_SELECT = "SET_SELECT";

export const REMOVE_SELECT = "REMOVE_SELECT";

export const setSelectWeighing = (selectValue, analysisID) => {
  return {
    type: SET_SELECT,
    selectValue,
    analysisID,
  };
};

export const removeSelectWeighing = () => {
  return {
    type: REMOVE_SELECT,
  };
};
