import axios from "axios";

export const languageConnector = (baseDomain) => {
  const conn = axios.create({
    baseURL: baseDomain,
    timeout: 10000,
  });

  conn.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );

  return conn;
};
