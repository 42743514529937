export const LOAD_PLAYERS = "LOAD_PLAYERS";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const DELETE_PLAYER = "DELETE_PLAYER";
export const EDIT_PLAYER = "EDIT_PLAYER";
export const CLEAN_PLAYERS = "CLEAN_PLAYERS";
export const LOAD_PLAYER_TO_EDIT = "LOAD_PLAYER_TO_EDIT";
export const SET_PLAYER_TO_EDIT = "SET_PLAYER_TO_EDIT";

export const loadPlayers = (payload) => {
  return {
    type: LOAD_PLAYERS,
    payload,
  };
};

export const loadPlayerToEdit = (payload) => {
  return {
    type: LOAD_PLAYER_TO_EDIT,
    payload,
  };
};

export const setPlayerToEdit = (payload) => {
  return {
    type: SET_PLAYER_TO_EDIT,
    payload,
  };
};

export const createPlayer = (payload) => {
  return {
    type: CREATE_PLAYER,
    payload,
  };
};

export const deletePlayer = (payload) => {
  return {
    type: DELETE_PLAYER,
    payload,
  };
};

export const editPlayer = (payload) => {
  return {
    type: EDIT_PLAYER,
    payload,
  };
};

export const cleanPlayers = () => {
  return {
    type: DELETE_PLAYER,
  };
};
