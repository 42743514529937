const endpoints = {
  /* API AUTH ENDPOINTS */
  TOKEN: "/login-check",
  FORGOR_PASSWORD: "/no-pass-check",
  RESET_PASSWORD: "/profile/reset-password",
  USER_INFO: "/profile/me.jsonld?trxId={trxId}",
  LOGOUT: "/logout",
  REFRESH_TOKEN: "/token/refresh",
  ORGANIZATION_LIST: "/plan?section=resources&organisationId={id1}&type=user",
  SITE_LANGUAGE: "/api/v2/infos/site.json?siteId={siteId}",

  GREYLOG: "https://logsys.rezilio.net:12202/gelf",

  /* API RISK ENDPOINTS */
  USERS_ME: "/users/me",

  LOCALES: "/locales/{id1}",
  ALL_LOCALES: "/locales",

  ACCESSES: "/accesses/{id1}",
  ALL_ACCESSES: "/accesses",
  ACCESSES_ADD: "/accesses/add",

  ANALYSES: "/analyses/{id1}",
  ALL_ANALYSES: "/analyses",
  ANALYSES_BUSINESS_UNITS: "/analyses/{id1}/business_units",
  ANALYSES_DUPLICATE: "/analyses/{id1}/duplicate",
  ANALYSES_EXPOSED_ELEMENTS: "/analyses/{id1}/exposed_elements",
  ANALYSES_GROUPS: "/analyses/{id1}/groups",
  ANALYSES_MATRIX: "/analyses/{id1}/matrix",
  ANALYSES_SITES: "/analyses/{id1}/sites",
  ANALYSES_THREATS: "/analyses/{id1}/threats",
  ANALYSES_TRANSLATIONS: "/translations?analysis={id1}&page=1",

  BUSINESS_UNITS: "/business_units/{id1}",
  ALL_BUSINESS_UNITS: "/business_units",

  EXPOSED_ELEMENTS: "/exposed_elements/{id1}",
  ALL_EXPOSED_ELEMENTS: "/exposed_elements",

  GROUPS: "/groups/{id1}",
  ALL_GROUPS: "/groups",

  MATRICES: "/matrices/{id1}",
  ALL_MATRICES: "/matrices",
  SET_WEIGHING: "/matrices/{id1}/setValues",
  GET_WEIGHING: "/matrices/{id1}/getValues?site=/api/v1/sites/{id2}",
  GET_WEIGHING_BY_GROUP: "/matrices/{id1}/getValues?group=/api/v1/groups/{id2}",
  GET_ALL_WEIGHING: "/matrices/{id1}/getValues",
  VALIDATE_WEIGHING: "/matrices/{id1}/validate",
  SET_COLUMN_ORDER: "/matrices/{id1}/setOrder",

  SITES: "/sites/{id1}",
  ALL_SITES: "/sites",
  SITES_PLAYERS: "/sites/{id1}/players",
  SITE_EXPOSED_ELEMENTS: "/sites/{id1}/exposed_elements",
  SITE_ADD_EXPOSED: "/sites/{id1}/add_ee",
  SITE_REMOVE_EXPOSED: "/sites/{id1}/remove_ee",

  THREATS: "/threats/{id1}",
  ALL_THREATS: "/threats",
  THREAT_EXPOSED_ELEMENTS: "/threats/{id1}/exposed_elements",
  THREAT_ADD_EXPOSED: "/threats/{id1}/addEE",
  THREAT_REMOVE_EXPOSED: "/threats/{id1}/removeEE",

  TRANSLATIONS: "/translations/{id1}",
  ALL_TRANSLATIONS: "/translations",

  ANALYSIS_TYPES: "/analysis_types/{id1}",
  ALL_ANALYSIS_TYPES: "/analysis_types",

  GET_ALL_VERSION: "/matrix_archives?site=/api/v1/sites/{id1}",
  GET_VERSION: "/matrix_archives/{id1}",
  GET_VERSION_RESUME:
    "/matrix_archives?site=/api/v1/sites/{id1}&properties[]=id&properties[]=author&properties[]=title&properties[]=site&properties[]=createdAt",

  CLASSIFICATIONS: "/matrix_classifications/{id1}",
  ALL_CLASSIFICATIONS: "/matrix_classifications",

  COLUMNS: "/matrix_columns/{id1}",
  ALL_COLUMNS: "/matrix_columns",

  CONSEQUENCES: "/matrix_consequences/{id1}",
  ALL_CONCEQUENCES: "/matrix_consequences",

  PROBABILITY_CRITERIAS: "/matrix_probabilities/{id1}",
  ALL_PROBABILITY_CRITERIAS: "/matrix_probabilities",

  PROBABILITY_LEVELS: "/matrix_probability_levels/{id1}",
  ALL_PROBABILITY_LEVELS: "/matrix_probability_levels",

  TREATMENTS: "/matrix_treatments/{id1}",
  ALL_TREATMENTS: "/matrix_treatments",

  PLAYERS: "/players/{id1}",
  ALL_PLAYERS: "/players",

  PROJECTS: "/projects/{id1}",
  ALL_PROJECTS: "/projects",
  PROJECT_ANALYSES: "/analyses?project={id1}?page=1",
  PROJECT_SECTORS: "/sectors?project={id1}?page=1",
  PROJECT_CATEGORIES: "/threat_categories?project={id1}?page=1",
  PROJECT_ANALYSIS_TYPES: "/analysis_types?project={id1}?page=1",
  SET_SECTOR_ORDER: "/projects/{id1}/setSectorOrder",
  SET_ANALYSIS_TYPE_ORDER: "/projects/{id1}/setAnalysisTypeOrder",
  SET_CATEGORY_ORDER: "/projects/{id1}/setThreatCategoryOrder",

  SECTORS: "/sectors/{id1}",
  ALL_SECTORS: "/sectors",

  CATEGORIES: "/threat_categories/{id1}",
  ALL_CATEGORIES: "/threat_categories",

  USERS: "/users/{id1}",
  ALL_USERS: "/users",
};

export default endpoints;
