export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";
export const SHOW_SUCCESS_MODAL = "SHOW_SUCCESS_MODAL";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const HIDE_MODAL = "HIDE_MODAL";

export const showModal = (payload = "generic.warning.message") => {
  let title = "";
  let code = "";
  let message = "";

  if (payload.status >= 500) {
    title = "generic.warning.5xx";
  } else if (payload.status >= 400) {
    title =
      payload.status === 401 ? "generic.warning.title" : "generic.warning.4xx";
  } else {
    title = "generic.warning.error";
  }

  code = `E${payload.status}:`;
  message =
    payload.data.message === undefined
      ? payload.data.detail
      : payload.data.message;

  return {
    type: SHOW_ERROR_MODAL,
    payload: { code, title, message },
  };
};

export const showSuccessModal = (messageID) => {
  return {
    type: SHOW_SUCCESS_MODAL,
    payload: messageID,
  };
};

export const showInvalidMatrixModal = (messageID, category, column) => {
  return {
    type: VALIDATION_ERROR,
    payload: { messageID, category, column },
  };
};

export const showGenericModal = () => {
  return {
    type: SHOW_ERROR_MODAL,
    payload: "generic.warning.message",
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
