export const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";
export const SHOW_CONFIRMATION_MODAL = "SHOW_CONFIRMATION_MODAL";
export const HIDE_DELETE_MODAL = "HIDE_DELETE_MODAL";

export const openDeleteModal = (
  handler,
  message = "generic.elimination.message"
) => {
  const payload = {
    handler,
    message,
  };
  return {
    type: SHOW_DELETE_MODAL,
    payload,
  };
};

export const openConfirmationModal = (
  handler,
  message = "generic.confirmation.message"
) => {
  const payload = {
    handler,
    message,
  };
  return {
    type: SHOW_CONFIRMATION_MODAL,
    payload,
  };
};

export const closeDeleteModal = () => {
  return {
    type: HIDE_DELETE_MODAL,
  };
};
