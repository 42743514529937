export const RESET_PASSWORD = "RESET_PASSWORD";
export const EMAIL_STEP_SUCCESS = "EMAIL_STEP_SUCCESS";
export const CODE_STEP_SUCCESS = "CODE_STEP_SUCCESS";
export const PASSWORD_STEP_SUCCESS = "PASSWORD_STEP_SUCCESS";
export const SUCCESS_STEP_SUCCESS = "SUCCESS_STEP_SUCCESS";

export const resertPassword = () => {
  return {
    type: RESET_PASSWORD,
  };
};

export const emailStepSuccess = (payload) => {
  return {
    type: EMAIL_STEP_SUCCESS,
    payload,
  };
};

export const codeStepSuccess = (payload) => {
  return {
    type: CODE_STEP_SUCCESS,
    payload,
  };
};

export const passwordStepSuccess = (payload) => {
  return {
    type: PASSWORD_STEP_SUCCESS,
    payload,
  };
};

export const successStepSuccess = () => {
  return {
    type: SUCCESS_STEP_SUCCESS,
  };
};
