import React from "react";
import Loader from "../loader/Loader";

const SectionLoader = () => {
  return (
    <div className="row my-5 py-5 justify-content-center align-items-center">
      <Loader />
    </div>
  );
};

export default SectionLoader;
