export const SHOW_VALIDATION_MODAL = "SHOW_VALIDATION_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const showValidationModal = (messageID, payload, matrixID, handler) => {
  return {
    type: SHOW_VALIDATION_MODAL,
    messageID: messageID,
    matrixID: matrixID,
    payload: payload,
    handler: handler,
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
