export const LOAD_EXPOSEDS = "LOAD_EXPOSEDS";
export const CREATE_EXPOSED = "CREATE_EXPOSED";
export const DELETE_EXPOSED = "DELETE_EXPOSED";
export const EDIT_EXPOSED = "EDIT_EXPOSED";
export const CLEAN_EXPOSEDS = "CLEAN_EXPOSEDS";
export const LOAD_EXPOSED_TO_EDIT = "LOAD_EXPOSED_TO_EDIT";
export const SET_EXPOSED_TO_EDIT = "SET_EXPOSED_TO_EDIT";

export const loadExposeds = (payload) => {
  return {
    type: LOAD_EXPOSEDS,
    payload,
  };
};

export const loadExposedToEdit = (payload) => {
  return {
    type: LOAD_EXPOSED_TO_EDIT,
    payload,
  };
};

export const setExposedToEdit = (payload) => {
  return {
    type: SET_EXPOSED_TO_EDIT,
    payload,
  };
};

export const createExposed = (payload) => {
  return {
    type: CREATE_EXPOSED,
    payload,
  };
};

export const deleteExposed = (payload) => {
  return {
    type: DELETE_EXPOSED,
    payload,
  };
};

export const editExposed = (payload) => {
  return {
    type: EDIT_EXPOSED,
    payload,
  };
};

export const cleanExposeds = () => {
  return {
    type: CLEAN_EXPOSEDS,
  };
};
