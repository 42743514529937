export const LOAD_THREATS = "LOAD_THREATS";
export const LOAD_THREATS_BY_GROUP = "LOAD_THREATS_BY_GROUP";
export const CREATE_THREAT = "CREATE_THREAT";
export const DELETE_THREAT = "DELETE_THREAT";
export const EDIT_THREAT = "EDIT_THREAT";
export const CLEAN_THREATS = "CLEAN_THREATS";
export const LOAD_THREAT_TO_EDIT = "LOAD_THREAT_TO_EDIT";
export const SET_THREAT_TO_EDIT = "SET_THREAT_TO_EDIT";

export const LOAD_THREAT_EXPOSEDS = "LOAD_THREAT_EXPOSEDS";
export const ADD_THREAT_EXPOSED = "ADD_THREAT_EXPOSED";
export const REMOVE_THREAT_EXPOSED = "REMOVE_THREAT_EXPOSED";

export const loadThreats = (payload) => {
  return {
    type: LOAD_THREATS,
    payload,
  };
};

export const loadThreatsByGroup = (payload) => {
  return {
    type: LOAD_THREATS_BY_GROUP,
    payload,
  };
};

export const loadThreatExposeds = (payload) => {
  return {
    type: LOAD_THREAT_EXPOSEDS,
    payload,
  };
};

export const addThreatExposed = (payload) => {
  return {
    type: ADD_THREAT_EXPOSED,
    payload,
  };
};

export const removeThreatExposed = (payload) => {
  return {
    type: REMOVE_THREAT_EXPOSED,
    payload,
  };
};

export const loadThreatToEdit = (payload) => {
  return {
    type: LOAD_THREAT_TO_EDIT,
    payload,
  };
};

export const setThreatToEdit = (payload) => {
  return {
    type: SET_THREAT_TO_EDIT,
    payload,
  };
};

export const createThreat = (payload) => {
  return {
    type: CREATE_THREAT,
    payload,
  };
};

export const deleteThreat = (payload) => {
  return {
    type: DELETE_THREAT,
    payload,
  };
};

export const editThreat = (payload) => {
  return {
    type: EDIT_THREAT,
    payload,
  };
};

export const cleanThreats = () => {
  return {
    type: DELETE_THREAT,
  };
};
