import {
  /* COLUMNS */
  LOAD_MATRIX_COLUMNS,
  CREATE_MATRIX_COLUMN,
  SET_MATRIX_COLUMN_TO_EDIT,
  DELETE_MATRIX_COLUMN,
  EDIT_MATRIX_COLUMN,
  CLEAN_MATRIX_COLUMNS,
} from "../actions/columns.action";

const initialState = {
  columnList: [],
  currentColumn: null,
};

const ColumnReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MATRIX_COLUMNS:
      action.payload.sort((a, b) => (a.id < b.id ? -1 : 1));
      return {
        columnList: action.payload,
        currentColumn: null,
      };
    case CREATE_MATRIX_COLUMN:
      state.columnList.push(action.payload);
      return state;

    case SET_MATRIX_COLUMN_TO_EDIT:
      state.currentColumn = action.payload;
      return state;

    case DELETE_MATRIX_COLUMN:
      return deleteColumn(state, action.payload);

    case EDIT_MATRIX_COLUMN:
      return editColumn(state, action.payload);

    case CLEAN_MATRIX_COLUMNS:
      return initialState;

    default:
      return state;
  }
};

const deleteColumn = (state, columnID) => {
  const columns = state.columnList;
  const index = columns.findIndex((c) => c.id === columnID);
  columns.splice(index, 1);
  return {
    ...state,
    columnList: columns,
  };
};

const editColumn = (state, updatedColumn) => {
  const columns = state.columnList;
  const index = columns.findIndex((c) => c.id === updatedColumn.id);
  columns.splice(index, 1, updatedColumn);
  return {
    ...state,
    columnList: columns,
  };
};

export default ColumnReducer;
