import {
  STAGE_LOCATION,
  CLEAN_LOCATION,
  STAGE_ANALYSIS_ID,
} from "../actions/location.action";

const initialState = {
  locationData: null,
  analysisId: null,
};

const LocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAGE_LOCATION:
      return {
        ...state,
        locationData: action.payload,
      };
    case STAGE_ANALYSIS_ID:
      return {
        ...state,
        analysisId: action.payload,
      };
    case CLEAN_LOCATION:
      return initialState;
    default:
      return state;
  }
};

export default LocationReducer;
