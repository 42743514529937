export const PERMISSION_SETUSER = "PERMISSION_SETUSER";
export const CLEAN_PERMISSION = "CLEAN_PERMISSION";

export const setUserPermissionOnAnalysis = (project, role) => {
  return {
    type: PERMISSION_SETUSER,
    payload: { project, role },
  };
};

export const cleanPermissions = () => {
  return {
    type: CLEAN_PERMISSION,
  };
};
