/* TREATMENTS */
export const LOAD_MATRIX_TREATMENTS = "LOAD_MATRIX_TREATMENTS";
export const CREATE_MATRIX_TREATMENT = "CREATE_MATRIX_TREATMENT";
export const DELETE_MATRIX_TREATMENT = "DELETE_MATRIX_TREATMENT";
export const EDIT_MATRIX_TREATMENT = "EDIT_MATRIX_TREATMENT";
export const SET_MATRIX_TREATMENT_TO_EDIT = "SET_MATRIX_TREATMENT_TO_EDIT";
export const CLEAN_MATRIX_TREATMENTS = "CLEAN_MATRIX_TREATMENTS";

export const loadMatrixTreatments = (payload) => {
  return {
    type: LOAD_MATRIX_TREATMENTS,
    payload,
  };
};

export const addMatrixTreatment = (payload) => {
  return {
    type: CREATE_MATRIX_TREATMENT,
    payload,
  };
};

export const deleteMatrixTreatment = (payload) => {
  return {
    type: DELETE_MATRIX_TREATMENT,
    payload,
  };
};

export const editMatrixTreatment = (payload) => {
  return {
    type: EDIT_MATRIX_TREATMENT,
    payload,
  };
};

export const setMatrixTreatmentToEdit = (payload) => {
  return {
    type: SET_MATRIX_TREATMENT_TO_EDIT,
    payload,
  };
};

export const cleanMatrixTreatments = () => {
  return {
    type: CLEAN_MATRIX_TREATMENTS,
  };
};
