import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

const LanguageProvider = ({ children }) => {
  const { currentLanguage, currentDict } = useSelector(
    (state) => state.language
  );
  return (
    <IntlProvider
      locale={currentLanguage}
      messages={currentDict[currentLanguage]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
