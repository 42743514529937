const profileModel = (data) => {
  return {
    user: {
      id: data.user.id,
      name: data.user.name + " " + data.user.last_name,
      email: data.user.email,
      missionId: data.user.missionUserId,
      missionName: data.user.missionName,
      organizationId: data.user.organisationId,
      organizationName: data.user.organisationName,
    },
    address: {
      site: data.address.siteName,
      iso: data.address.iso,
    },
    role: data.roles,
    zoneUrl: new URL(data.zoneUrlV1).origin,
    zoneUrlV1: data.zoneUrlV1,
    zoneUrlV2: data.zoneUrlV2,
    zoneBaseUrl: data.zoneBaseUrl,
  };
};

export default profileModel;
