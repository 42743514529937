export const LOAD_PROJECT_LIST = "LOAD_PROJECT_LIST";
export const SET_PROJECT_TO_EDIT = "SET_PROJECT_TO_EDIT";
export const CLEAN_PROJECT_REDUCER = "CLEAN_PROJECT_REDUCER";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const CREATE_PROJECT = "EDIT_PROJECT";
export const LOAD_PROJECT_TO_EDIT = "LOAD_PROJECT_TO_EDIT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const DeleteProject = (payload) => {
  return {
    type: DELETE_PROJECT,
    payload,
  };
};

export const LoadProjectToEdit = (payload) => {
  return {
    type: LOAD_PROJECT_TO_EDIT,
    payload,
  };
};

export const LoadProjectList = (payload) => {
  return {
    type: LOAD_PROJECT_LIST,
    payload,
  };
};

export const CreateProject = (payload) => {
  return {
    type: CREATE_PROJECT,
    payload,
  };
};

export const SetProjectEdit = (payload) => {
  return {
    type: SET_PROJECT_TO_EDIT,
    payload,
  };
};

export const cleanProjectReducer = () => {
  return {
    type: CLEAN_PROJECT_REDUCER,
  };
};

export const EditSingleProject = (payload) => {
  return {
    type: EDIT_PROJECT,
    payload,
  };
};
