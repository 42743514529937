import {
  HIDE_MODAL,
  SHOW_VALIDATION_MODAL,
} from "../actions/validationModal.action";

const initialState = {
  show: false,
  titleClass: "bg-primary",
  titleID: "risk.weighing.validate",
  validateBody: {},
  isBodyLoaded: false,
  payload: {},
  handler: () => {},
  matrixID: null,
};

const ValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_VALIDATION_MODAL:
      return {
        show: true,
        titleID: "risk.weighing.validate",
        messageID: action.messageID,
        titleClass: "bg-primary",
        payload: action.payload,
        matrixID: action.matrixID,
        handler: action.handler,
      };

    case HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default ValidationReducer;
