import {
  LOAD_SITES,
  CREATE_SITE,
  EDIT_SITE,
  DELETE_SITE,
  CLEAN_SITES,
  LOAD_SITE_TO_EDIT,
  SET_SITE_TO_EDIT,
  LOAD_SITE_EXPOSEDS,
  ADD_SITE_EXPOSED,
  REMOVE_SITE_EXPOSED,
} from "../actions/sites.action";

const initialState = {
  areSitesLoaded: false,
  siteList: [],
  currentSite: null,
  siteExposedList: [],
};

const SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SITES:
      return {
        ...initialState,
        areSitesLoaded: true,
        siteList: action.payload,
      };

    case LOAD_SITE_EXPOSEDS:
      return {
        ...state,
        siteExposedList: action.payload,
      };

    case ADD_SITE_EXPOSED:
      return addSiteExposed(state, action.payload);

    case REMOVE_SITE_EXPOSED:
      return removeSiteExposed(state, action.payload);

    case CREATE_SITE:
      if (!state.areSitesLoaded) {
        return state;
      }
      const sites = state.siteList;
      sites.push(action.payload);
      return { ...state, siteList: sites };

    case SET_SITE_TO_EDIT:
      return {
        ...state,
        currentSite: action.payload,
      };

    case EDIT_SITE:
      if (!state.areSitesLoaded) {
        return state;
      }
      return editSite(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_SITE_TO_EDIT:
      if (!state.areSitesLoaded) {
        return state;
      }
      return loadSiteToEdit(JSON.parse(JSON.stringify(state)), action.payload);

    case DELETE_SITE:
      if (!state.areSitesLoaded) {
        return state;
      }
      return deleteSite(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_SITES:
      return initialState;

    default:
      return state;
  }
};

const addSiteExposed = (state, exposed) => {
  const exposeds = state.siteExposedList.slice();
  exposeds.push(exposed);
  return {
    ...state,
    siteExposedList: exposeds,
  };
};

const removeSiteExposed = (state, exposedID) => {
  const exposeds = state.siteExposedList;
  const index = exposeds.findIndex((ex) => ex.id === exposedID);
  exposeds.splice(index, 1);
  return {
    ...state,
    siteExposedList: exposeds,
  };
};

const editSite = (state, upatedSite) => {
  const sites = state.siteList;
  const index = sites.findIndex((s) => s.id === upatedSite.id);
  sites.splice(index, 1, upatedSite);
  return { ...state, siteList: sites };
};

const deleteSite = (state, siteId) => {
  const sites = state.siteList;
  const index = sites.findIndex((g) => g.id === siteId);
  sites.splice(index, 1);
  return { ...state, siteList: sites };
};

const loadSiteToEdit = (state, siteId) => {
  const sites = state.siteList;
  const selectedSite = sites.find((s) => s.id === siteId);
  return {
    ...state,
    currentSite: selectedSite,
  };
};

export default SiteReducer;
