import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import usePrevious from "../../../hooks/use-previous/usePrevious";
import { stageLocation } from "../../../store/actions/location.action";

const LocationProvider = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    dispatch(stageLocation(prevLocation));
  }, [location, dispatch, prevLocation]);
  return null;
};

export default LocationProvider;
