export const LOAD_ACCESSES = "LOAD_ACCESSES";
export const CREATE_ACCESS = "CREATE_ACCESS";
export const DELETE_ACCESS = "DELETE_ACCESS";
export const EDIT_ACCESS = "EDIT_ACCESS";
export const CLEAN_ACCESSES = "CLEAN_ACCESSES";
export const LOAD_ACCESS_TO_EDIT = "LOAD_ACCESS_TO_EDIT";
export const SET_ACCESS_TO_EDIT = "SET_ACCESS_TO_EDIT";

export const loadAccesses = (projectUsers = [], OrganizationUsers = []) => {
  return {
    type: LOAD_ACCESSES,
    payload: { projectUsers, OrganizationUsers },
  };
};

export const loadAccessToEdit = (payload) => {
  return {
    type: LOAD_ACCESS_TO_EDIT,
    payload,
  };
};

export const setAccessToEdit = (payload) => {
  return {
    type: SET_ACCESS_TO_EDIT,
    payload,
  };
};

export const createAccess = (payload) => {
  return {
    type: CREATE_ACCESS,
    payload,
  };
};

export const deleteAccess = (payload) => {
  return {
    type: DELETE_ACCESS,
    payload,
  };
};

export const editAccess = (payload) => {
  return {
    type: EDIT_ACCESS,
    payload,
  };
};

export const cleanAccesses = () => {
  return {
    type: CLEAN_ACCESSES,
  };
};
