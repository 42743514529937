import {
  LOAD_ANALYSIS_TYPES,
  CREATE_ANALYSIS_TYPE,
  EDIT_ANALYSIS_TYPE,
  DELETE_ANALYSIS_TYPE,
  CLEAN_ANALYSIS_TYPES,
  LOAD_ANALYSIS_TYPE_TO_EDIT,
  SET_ANALYSIS_TYPE_TO_EDIT,
} from "../actions/analysis_types.action";

const initialState = {
  areAnalysisTypeLoaded: false,
  analysisTypeList: [],
  currentAnalysisType: null,
};

const TypeAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ANALYSIS_TYPES:
      return {
        areAnalysisTypeLoaded: true,
        analysisTypeList: action.payload,
        currentAnalysisType: null,
      };

    case CREATE_ANALYSIS_TYPE:
      if (!state.areAnalysisTypeLoaded) {
        return state;
      }
      const analysisTypes = state.analysisTypeList;
      analysisTypes.push(action.payload);
      return { ...state, analysisTypeList: analysisTypes };

    case SET_ANALYSIS_TYPE_TO_EDIT:
      return {
        ...state,
        currentAnalysisType: action.payload,
      };

    case EDIT_ANALYSIS_TYPE:
      if (!state.areAnalysisTypeLoaded) {
        return state;
      }
      return editTypeAnalysis(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case LOAD_ANALYSIS_TYPE_TO_EDIT:
      if (!state.areAnalysisTypeLoaded) {
        return state;
      }
      return loadTypeanalysis(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_ANALYSIS_TYPE:
      if (!state.areAnalysisTypeLoaded) {
        return state;
      }
      return deleteTypeAnalysis(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case CLEAN_ANALYSIS_TYPES:
      return initialState;

    default:
      return state;
  }
};

const editTypeAnalysis = (state, updatedTypeAnalysis) => {
  const analysisTypes = state.analysisTypeList;
  const index = analysisTypes.findIndex((t) => t.id === updatedTypeAnalysis.id);
  analysisTypes.splice(index, 1, updatedTypeAnalysis);

  return { ...state, analysisTypeList: analysisTypes };
};

const deleteTypeAnalysis = (state, typeAnalysisID) => {
  const analysisTypes = state.analysisTypeList;
  const index = analysisTypes.findIndex((t) => t.id === typeAnalysisID);
  analysisTypes.splice(index, 1);

  return { ...state, analysisTypeList: analysisTypes };
};

const loadTypeanalysis = (state, typeAnalysisID) => {
  const analysisTypes = state.analysisTypeList;
  const selectedTypeAnalysis = analysisTypes.find(
    (t) => t.id === typeAnalysisID
  );
  return {
    ...state,
    currentAnalysisType: selectedTypeAnalysis,
  };
};

export default TypeAnalysisReducer;
