import {
  LOAD_GROUPS,
  CREATE_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  CLEAN_GROUPS,
  LOAD_GROUP_TO_EDIT,
  SET_GROUP_TO_EDIT,
} from "../actions/groups.action";

const initialState = {
  areGroupsLoaded: false,
  groupList: [],
  currentGroup: null,
};

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUPS:
      return {
        areGroupsLoaded: true,
        groupList: action.payload,
        currentGroup: null,
      };

    case CREATE_GROUP:
      if (!state.areGroupsLoaded) {
        return state;
      }
      const groups = state.groupList;
      groups.push(action.payload);
      return { ...state, groupList: groups };

    case SET_GROUP_TO_EDIT:
      return {
        ...state,
        currentGroup: action.payload,
      };

    case EDIT_GROUP:
      if (!state.areGroupsLoaded) {
        return state;
      }
      return editGroup(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_GROUP_TO_EDIT:
      if (!state.areGroupsLoaded) {
        return state;
      }
      return loadGroupToEdit(JSON.parse(JSON.stringify(state)), action.payload);

    case DELETE_GROUP:
      if (!state.areGroupsLoaded) {
        return state;
      }
      return deleteGroup(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_GROUPS:
      return initialState;

    default:
      return state;
  }
};

const editGroup = (state, upatedGroup) => {
  const groups = state.groupList;
  const index = groups.findIndex((g) => g.id === upatedGroup.id);
  groups.splice(index, 1, upatedGroup);
  return { ...state, groupList: groups };
};

const deleteGroup = (state, groupId) => {
  const groups = state.groupList;
  const index = groups.findIndex((g) => g.id === groupId);
  groups.splice(index, 1);
  return { ...state, groupList: groups };
};

const loadGroupToEdit = (state, groupId) => {
  const groups = state.groupList;
  const selectedGroup = groups.find((g) => g.id === groupId);
  return {
    ...state,
    currentGroup: selectedGroup,
  };
};

export default GroupReducer;
