export const STAGE_LOCATION = "STAGE_LOCATION";
export const STAGE_ANALYSIS_ID = "STAGE_ANALYSIS_ID";
export const CLEAN_LOCATION = "CLEAN_LOCATION";

export const stageLocation = (payload) => {
  return {
    type: STAGE_LOCATION,
    payload,
  };
};

export const stageAnalysisId = (payload) => {
  return {
    type: STAGE_ANALYSIS_ID,
    payload,
  };
};

export const cleanLocation = () => {
  return {
    type: CLEAN_LOCATION,
  };
};
