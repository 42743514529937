export const LOAD_SITES = "LOAD_SITES";
export const CREATE_SITE = "CREATE_SITE";
export const DELETE_SITE = "DELETE_SITE";
export const EDIT_SITE = "EDIT_SITE";
export const CLEAN_SITES = "CLEAN_SITES";
export const LOAD_SITE_TO_EDIT = "LOAD_SITE_TO_EDIT";
export const SET_SITE_TO_EDIT = "SET_SITE_TO_EDIT";

export const LOAD_SITE_EXPOSEDS = "LOAD_SITE_EXPOSEDS";
export const ADD_SITE_EXPOSED = "ADD_SITE_EXPOSED";
export const REMOVE_SITE_EXPOSED = "REMOVE_SITE_EXPOSED";

export const loadSites = (payload) => {
  return {
    type: LOAD_SITES,
    payload,
  };
};

export const loadSiteExposeds = (payload) => {
  return {
    type: LOAD_SITE_EXPOSEDS,
    payload,
  };
};

export const addSiteExposed = (payload) => {
  return {
    type: ADD_SITE_EXPOSED,
    payload,
  };
};

export const removeSiteExposed = (payload) => {
  return {
    type: REMOVE_SITE_EXPOSED,
    payload,
  };
};

export const loadSiteToEdit = (payload) => {
  return {
    type: LOAD_SITE_TO_EDIT,
    payload,
  };
};

export const setSiteToEdit = (payload) => {
  return {
    type: SET_SITE_TO_EDIT,
    payload,
  };
};

export const createSite = (payload) => {
  return {
    type: CREATE_SITE,
    payload,
  };
};

export const deleteSite = (payload) => {
  return {
    type: DELETE_SITE,
    payload,
  };
};

export const editSite = (payload) => {
  return {
    type: EDIT_SITE,
    payload,
  };
};

export const cleanSites = () => {
  return {
    type: CLEAN_SITES,
  };
};
