export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const CLEAN_CATEGORIES = "CLEAN_CATEGORIES";
export const LOAD_CATEGORY_TO_EDIT = "LOAD_CATEGORY_TO_EDIT";
export const SET_CATEGORY_TO_EDIT = "SET_CATEGORY_TO_EDIT";

export const loadCategories = (payload) => {
  return {
    type: LOAD_CATEGORIES,
    payload,
  };
};

export const loadCategoryToEdit = (payload) => {
  return {
    type: LOAD_CATEGORY_TO_EDIT,
    payload,
  };
};

export const setCategoryToEdit = (payload) => {
  return {
    type: SET_CATEGORY_TO_EDIT,
    payload,
  };
};

export const createCategory = (payload) => {
  return {
    type: CREATE_CATEGORY,
    payload,
  };
};

export const deleteCategory = (payload) => {
  return {
    type: DELETE_CATEGORY,
    payload,
  };
};

export const editCategory = (payload) => {
  return {
    type: EDIT_CATEGORY,
    payload,
  };
};

export const cleanCategories = () => {
  return {
    type: CLEAN_CATEGORIES,
  };
};
