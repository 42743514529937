import {
  /* PROBABILITIES */
  LOAD_MATRIX_PROBABILITY_CRITERIAS,
  LOAD_MATRIX_PROBABILITY_LEVELS,
  SET_MATRIX_PROBABILITY_TO_EDIT,
  EDIT_MATRIX_PROBABILITY_CRITERIA,
  EDIT_MATRIX_PROBABILITY_LEVELS,
  CLEAN_MATRIX_PROBABILITIES,
} from "../actions/probabilities.action";

const initialState = {
  probabilityList: [],
  probabilityLevels: null,
  currentProbability: null,
};

const ProbabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MATRIX_PROBABILITY_CRITERIAS:
      const sortedProbabilities = action.payload.sort((a, b) =>
        a.category < b.category ? 1 : -1
      );
      return {
        ...state,
        probabilityList: sortedProbabilities,
        currentProbability: null,
      };

    case LOAD_MATRIX_PROBABILITY_LEVELS:
      return {
        ...state,
        probabilityLevels: action.payload,
      };

    case SET_MATRIX_PROBABILITY_TO_EDIT:
      state.currentProbability = action.payload;
      return state;

    case EDIT_MATRIX_PROBABILITY_CRITERIA:
      return editProbability(state, action.payload);

    case EDIT_MATRIX_PROBABILITY_LEVELS:
      state.probabilityLevels = action.payload;
      return state;

    case CLEAN_MATRIX_PROBABILITIES:
      return initialState;

    default:
      return state;
  }
};

const editProbability = (state, updatedProbability) => {
  const probabilities = state.probabilityList;
  const index = probabilities.findIndex((p) => p.id === updatedProbability.id);
  probabilities.splice(index, 1, updatedProbability);
  return {
    ...state,
    probabilityList: probabilities,
  };
};

export default ProbabilityReducer;
