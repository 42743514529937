import {
  /* CLASSIFICATIONS */
  LOAD_MATRIX_CLASSIFICATIONS,
  SET_MATRIX_CLASSIFICATION_TO_EDIT,
  EDIT_MATRIX_CLASSIFICATION,
  CLEAN_MATRIX_CLASSIFICATIONS,
} from "../actions/classifications.action";

const initialState = {
  classificationList: [],
  currentClassification: null,
};

const ClassificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MATRIX_CLASSIFICATIONS:
      return {
        classificationList: action.payload,
        currentClassification: null,
      };

    case SET_MATRIX_CLASSIFICATION_TO_EDIT:
      state.currentClassification = action.payload;
      return state;

    case EDIT_MATRIX_CLASSIFICATION:
      return editClassification(state, action.payload);

    case CLEAN_MATRIX_CLASSIFICATIONS:
      return initialState;

    default:
      return state;
  }
};

const editClassification = (state, updatedClassification) => {
  const classifications = state.classificationList;
  const index = classifications.findIndex(
    (c) => c.id === updatedClassification.id
  );
  classifications.splice(index, 1, updatedClassification);
  return {
    ...state,
    classificationList: classifications,
  };
};

export default ClassificationReducer;
