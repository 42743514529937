import en from "./en.json";
import fr from "./fr.json";
import es from "./es.json";

export const ENGLISH = "en";

export const ENGLISH_ID = 1;

export const FRENCH = "fr";

export const FRENCH_ID = 2;

export const SPANISH = "es";

export const SPANISH_ID = 3;

export const messages = { en, fr, es };

export const languages = [ENGLISH, FRENCH, SPANISH];

export const defaultLanguage = ENGLISH;

export const defaultInnerLanguage = "en_CA";
