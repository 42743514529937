import {
  LOAD_ANALYSES,
  CREATE_ANALYSIS,
  EDIT_ANALYSIS,
  DELETE_ANALYSIS,
  CLEAN_ANALYSES,
  LOAD_ANALYSIS_TO_EDIT,
  SET_ANALYSIS_TO_EDIT,
} from "../actions/analyses.action";

const initialState = {
  areAnalysisLoaded: false,
  analysisList: [],
  currentAnalysis: null,
};

const AnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ANALYSES:
      return {
        areAnalysisLoaded: true,
        analysisList: action.payload,
        currentAnalysis: null,
      };

    case CREATE_ANALYSIS:
      if (!state.areAnalysisLoaded) {
        return state;
      }
      const analyses = state.analysisList;
      analyses.push(action.payload);
      return { ...state, analysisList: analyses };

    case SET_ANALYSIS_TO_EDIT:
      return {
        ...state,
        currentAnalysis: action.payload,
      };

    case EDIT_ANALYSIS:
      if (!state.areAnalysisLoaded) {
        return state;
      }
      return editAnalysis(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_ANALYSIS_TO_EDIT:
      if (!state.areAnalysisLoaded) {
        return state;
      }
      return loadAnalysisToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_ANALYSIS:
      if (!state.areAnalysisLoaded) {
        return state;
      }
      return deleteAnalysis(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_ANALYSES:
      return initialState;

    default:
      return state;
  }
};

const editAnalysis = (state, updatedAnalysis) => {
  const analyses = state.analysisList;
  const index = analyses.findIndex((a) => a.id === updatedAnalysis.id);
  analyses.splice(index, 1, updatedAnalysis);
  return { ...state, analysisList: analyses };
};

const deleteAnalysis = (state, analysisID) => {
  const analyses = state.analysisList;
  const index = analyses.findIndex((s) => s.id === analysisID);
  analyses.splice(index, 1);
  return { ...state, analysisList: analyses };
};

const loadAnalysisToEdit = (state, analysisID) => {
  const analyses = state.analysisList;
  const selectedAnalysis = analyses.find((s) => s.id === analysisID);
  return {
    ...state,
    currentAnalysis: selectedAnalysis,
  };
};

export default AnalysisReducer;
