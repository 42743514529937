import store from "../../utils/localstorage";
import jwt from "../../utils/jwt.service";

import {
  USER_LOGIN,
  USER_LOGOUT,
  SET_PROFILE,
  REMOVE_PROFILE,
} from "../actions/auth.action";

let initialState = {
  is_authenticated: false,
  profile: null,
};

jwt.checkStaoragedToken();

if (jwt.getToken()) {
  initialState = {
    is_authenticated: true,
    profile: store.getProfile(),
  };
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        is_authenticated: true,
      };
    case USER_LOGOUT:
      jwt.cleanUserData();
      return {
        is_authenticated: false,
        profile: null,
      };
    case SET_PROFILE:
      store.saveProfile(action.payload);
      return {
        is_authenticated: true,
        profile: action.payload,
      };
    case REMOVE_PROFILE:
      return {
        is_authenticated: false,
        profile: null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
