import React, { Suspense } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import history from "./history";
import navRoutes from "./constants/navigationRoutes";
import ScroolToTop from "./ScroolToTop";
import SectionLoader from "../components/common/section-loader/SectionLoader";
import LocationProvider from "../components/location/location-provider/LocationProvider";

/* PUBLIC ROUTES */
const Home = React.lazy(() => import("./../views/public-views/home/Home"));
const Login = React.lazy(() => import("./../views/public-views/login/Login"));
const ResetPassword = React.lazy(() =>
  import("../views/public-views/reset-password/ResetPassword")
);

/* PRIVATE ROUTES */
const ProjectList = React.lazy(() =>
  import("../views/private-views/project/project-list/ProjectList")
);
const ProjectCreate = React.lazy(() =>
  import("../views/private-views/project/project-create/ProjectCreate")
);
const ProjectEdit = React.lazy(() =>
  import("../views/private-views/project/project-edit/ProjectEdit")
);

const AccessList = React.lazy(() =>
  import("../views/private-views/access/access-list/AccessList")
);
const AccessCreate = React.lazy(() =>
  import("../views/private-views/access/access-create/AccessCreate")
);
const AccessEdit = React.lazy(() =>
  import("../views/private-views/access/access-edit/AccessEdit")
);

const TranslationEdit = React.lazy(() =>
  import("../views/private-views/translation/translation-edit/TranslationEdit")
);

const GroupCreate = React.lazy(() =>
  import("../views/private-views/group/group-create/GroupCreate")
);
const GroupEdit = React.lazy(() =>
  import("../views/private-views/group/group-edit/GroupEdit")
);

const BusinessCreate = React.lazy(() =>
  import("../views/private-views/business/business-create/BusinessCreate")
);
const BusinessEdit = React.lazy(() =>
  import("../views/private-views/business/business-edit/BusinessEdit")
);

const SectorCreate = React.lazy(() =>
  import("../views/private-views/sector/sector-create/SectorCreate")
);
const SectorEdit = React.lazy(() =>
  import("../views/private-views/sector/sector-edit/SectorEdit")
);

const AnalysisTypeCreate = React.lazy(() =>
  import(
    "../views/private-views/analysis_type/analysis_type_create/AnalysisTypeCreate"
  )
);
const AnalysisTypeEdit = React.lazy(() =>
  import(
    "../views/private-views/analysis_type/analysis_type_edit/AnalysisTypeEdit"
  )
);

const CategoryCreate = React.lazy(() =>
  import("../views/private-views/category/category-create/CategoryCreate")
);
const CategoryEdit = React.lazy(() =>
  import("../views/private-views/category/category-edit/CategoryEdit")
);

const ThreatCreate = React.lazy(() =>
  import("../views/private-views/threat/threat-create/ThreatCreate")
);
const ThreatEdit = React.lazy(() =>
  import("../views/private-views/threat/threat-edit/ThreatEdit")
);

const SiteCreate = React.lazy(() =>
  import("../views/private-views/site/site-create/SiteCreate")
);
const SiteEdit = React.lazy(() =>
  import("../views/private-views/site/site-edit/SiteEdit")
);

const PlayerCreate = React.lazy(() =>
  import("../views/private-views/player/player-create/PlayerCreate")
);
const PlayerEdit = React.lazy(() =>
  import("../views/private-views/player/player-edit/PlayerEdit")
);

const RiskMatrix = React.lazy(() =>
  import("../views/private-views/risk/risk-matrix/RiskMatrix")
);
const RiskWeighing = React.lazy(() =>
  import("../views/private-views/risk/risk-weighing/RiskWeighing")
);
const RiskVisualization = React.lazy(() =>
  import("../views/private-views/risk/risk-visualization/RiskVisualization")
);

const RiskColumnCreate = React.lazy(() =>
  import("../views/private-views/risk/risk-column-create/RiskColumnCreate")
);
const RiskColumnEdit = React.lazy(() =>
  import("../views/private-views/risk/risk-column-edit/RiskColumnEdit")
);

const RiskConsequenceEdit = React.lazy(() =>
  import(
    "../views/private-views/risk/risk-consequence-edit/RiskConsequenceEdit"
  )
);

const RiskClassificationEdit = React.lazy(() =>
  import(
    "../views/private-views/risk/risk-classification-edit/RiskClassificationEdit"
  )
);

const RiskTreatmentCreate = React.lazy(() =>
  import(
    "../views/private-views/risk/risk-treatment-create/RiskTreatmentCreate"
  )
);
const RiskTreatmentEdit = React.lazy(() =>
  import("../views/private-views/risk/risk-treatment-edit/RiskTreatmentEdit")
);

const RiskProbCriteriaEdit = React.lazy(() =>
  import(
    "../views/private-views/risk/risk-prob-criteria-edit/RiskProbCriteriaEdit"
  )
);
const RiskProbLevelEdit = React.lazy(() =>
  import("../views/private-views/risk/risk-prob-level-edit/RiskProbLevelEdit")
);

const MainApp = React.lazy(() =>
  import("../views/private-views/analysis/analysis-archive/AnalysisArchive")
);

const AnalysisCreate = React.lazy(() =>
  import("../views/private-views/analysis/analysis-create/AnalysisCreate")
);

const AnalysisEdit = React.lazy(() =>
  import("../views/private-views/analysis/analysis-edit/AnalysisEdit")
);

const AnalysisDashboard = React.lazy(() =>
  import("../views/private-views/analysis/analysis-dashboard/AnalysisDashboard")
);

const publicPages = [
  { path: navRoutes.HOME, component: Home },
  { path: navRoutes.LOGIN, component: Login },
  { path: navRoutes.RESET_PASSWORD, component: ResetPassword },
  { path: navRoutes.SUPPORT, component: () => <h1>SUPPORT</h1> },
  { path: navRoutes.NOT_FOUND, component: () => <h1>NOT_FOUND</h1> },
  { path: navRoutes.AUTHCHECK, component: () => <h1>AUTHCHECK</h1> },
  { path: navRoutes.ERROR, component: () => <h1>ERROR</h1> },
];

const privatePages = [
  /* MAIN PAGE */
  { path: navRoutes.APP, component: MainApp },
  /* ANALYSIS */
  { path: navRoutes.CREATE_ANALYSIS, component: AnalysisCreate },
  { path: navRoutes.EDIT_ANALYSIS, component: AnalysisEdit },
  { path: navRoutes.DASHBOARD_ANALYSIS, component: AnalysisDashboard },
  /* PROJECTS */
  { path: navRoutes.LIST_PROJECTS, component: ProjectList },
  { path: navRoutes.CREATE_PROJECT, component: ProjectCreate },
  { path: navRoutes.EDIT_PROJECT, component: ProjectEdit },
  /* ACCESS */
  { path: navRoutes.LIST_ACCESS, component: AccessList },
  { path: navRoutes.CREATE_ACCESS, component: AccessCreate },
  { path: navRoutes.EDIT_ACCESS, component: AccessEdit },
  /* TRANSLATIONS */
  { path: navRoutes.TRANSLATION_EDIT, component: TranslationEdit },
  /* GROUPS */
  { path: navRoutes.CREATE_GROUP, component: GroupCreate },
  { path: navRoutes.EDIT_GROUP, component: GroupEdit },
  /* SECTOR */
  { path: navRoutes.CREATE_SECTOR, component: SectorCreate },
  { path: navRoutes.EDIT_SECTOR, component: SectorEdit },
  /* ANALYSIS TYPES */
  { path: navRoutes.CREATE_TYPE_ANALYSIS, component: AnalysisTypeCreate },
  { path: navRoutes.EDIT_TYPE_ANALYSIS, component: AnalysisTypeEdit },
  /* THREAT CATEGORY */
  { path: navRoutes.CREATE_CATEGORY, component: CategoryCreate },
  { path: navRoutes.EDIT_CATEGORY, component: CategoryEdit },
  /* THREATS */
  { path: navRoutes.CREATE_THREAT, component: ThreatCreate },
  { path: navRoutes.EDIT_THREAT, component: ThreatEdit },
  /* SITES - PLAYERS */
  { path: navRoutes.CREATE_SITE, component: SiteCreate },
  { path: navRoutes.EDIT_SITE, component: SiteEdit },
  { path: navRoutes.CREATE_PLAYER, component: PlayerCreate },
  { path: navRoutes.EDIT_PLAYER, component: PlayerEdit },
  /* BUSINESS_UNITS */
  { path: navRoutes.CREATE_BUSSINESS, component: BusinessCreate },
  { path: navRoutes.EDIT_BUSSINESS, component: BusinessEdit },
  /* RISK_MATRICES */
  { path: navRoutes.MATRIX_RISK, component: RiskMatrix },
  { path: navRoutes.WEIGHING_RISK, component: RiskWeighing },
  { path: navRoutes.VISUALIZATION_RISK, component: RiskVisualization },
  /* MATRIX COLUMNS*/
  { path: navRoutes.CREATE_COLUMN, component: RiskColumnCreate },
  { path: navRoutes.EDIT_COLUMN, component: RiskColumnEdit },
  /* MATRIX CONSEQUENCES*/
  { path: navRoutes.EDIT_CONSEQUENCE, component: RiskConsequenceEdit },
  /* MATRIX CLASSIFICATIONS*/
  { path: navRoutes.EDIT_CLASSIFICATION, component: RiskClassificationEdit },
  /* MATRIX TREATMENTS*/
  { path: navRoutes.CREATE_TREATMENT, component: RiskTreatmentCreate },
  { path: navRoutes.EDIT_TREATMENT, component: RiskTreatmentEdit },
  /* MATRIX PROBABILITIES*/
  {
    path: navRoutes.EDIT_PROBABILITY_CRITERIA,
    component: RiskProbCriteriaEdit,
  },
  { path: navRoutes.EDIT_PROBABILITY_LEVEL, component: RiskProbLevelEdit },
];

const AppRouter = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<SectionLoader />}>
        <ScroolToTop />
        <LocationProvider />
        <Switch>
          {/* PUBLIC ROUTES */}
          {publicPages.map((page) => (
            <Route {...page} />
          ))}

          {/* PRIVATE ROUTES */}
          {privatePages.map((page) => (
            <PrivateRoute exact {...page} />
          ))}

          {/* Redirect */}
          <Route render={() => <Redirect to={navRoutes.LOGIN} />} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
