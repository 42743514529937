import {
  SHOW_DELETE_MODAL,
  SHOW_CONFIRMATION_MODAL,
  HIDE_DELETE_MODAL,
} from "../actions/delete.action";

const initialState = {
  show: false,
  handler: () => {},
  messageID: "generic.section.emptytitle",
  titleBG: "bg-warning",
  titleID: "generic.elimination.title",
  btnBG: "btn-danger",
  btnID: "generic.table.delete",
};

const confirmationState = {
  show: false,
  handler: () => {},
  messageID: "generic.section.emptytitle",
  titleBG: "bg-warning",
  titleID: "generic.confirmation.title",
  btnBG: "btn-primary",
  btnID: "generic.button.continue",
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DELETE_MODAL:
      return {
        ...initialState,
        show: true,
        handler: action.payload.handler,
        messageID: action.payload.message,
      };

    case SHOW_CONFIRMATION_MODAL:
      return {
        ...confirmationState,
        show: true,
        handler: action.payload.handler,
        messageID: action.payload.message,
      };

    case HIDE_DELETE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default ModalReducer;
