import store from "../../utils/localstorage";

import {
  defaultLanguage,
  defaultInnerLanguage,
  messages,
} from "../../locales/config-languages";

import {
  SWITCH_LANGUAGE,
  SET_ANALYSIS_DICTIONARY,
  SWITCH_TO_BASE_DICTIONARY,
  SWITCH_TO_ANALYSIS_DICTIONARY,
  EDIT_KEY_ON_ANALYSIS_DICTIONARY,
  SWTICH_INNER_LANGUAGES,
  SET_CURRENT_INNER_LANGUAGE,
} from "../actions/language.action";

const initialState = {
  currentLanguage: store.get("locale") || defaultLanguage,
  currentInnerLanguage:
    store.get("currentInnerLanguage") || defaultInnerLanguage,
  prevInnerLanguage: null,
  innerLanguages: store.get("innerLanguages") || [],
  languages: JSON.parse(store.get("innerLanguages")) || [],
  currentDict: messages,
  lastAnalysis: null,
  analysisDictValues: null,
  analysisDictIds: null,
};

const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      store.set("locale", action.payload.language);
      return {
        ...state,
        currentLanguage: action.payload.language,
      };

    case SET_ANALYSIS_DICTIONARY:
      return {
        ...state,
        currentDict: createFullProjectDictionary(
          action.payload.analysisDictValues
        ),
        lastAnalysis: action.payload.project,
        analysisDictValues: action.payload.analysisDictValues,
        analysisDictIds: action.payload.analysisDictIds,
      };

    case EDIT_KEY_ON_ANALYSIS_DICTIONARY:
      const { key, value, id } = action.payload;
      return editKeyOnAnalysisDictionary(key, value, id, state);

    case SWITCH_TO_BASE_DICTIONARY:
      return {
        ...state,
        currentDict: messages,
      };

    case SWITCH_TO_ANALYSIS_DICTIONARY:
      return {
        ...state,
        currentDict: createFullProjectDictionary(state.analysisDictValues),
      };

    case SWTICH_INNER_LANGUAGES:
      store.set("innerLanguages", action.payload.innerLanguages);
      return {
        ...state,
        innerLanguages: action.payload.innerLanguages,
      };

    case SET_CURRENT_INNER_LANGUAGE:
      store.set("currentInnerLanguage", action.payload.currentInnerLanguage);
      return {
        ...state,
        prevInnerLanguage: compareLanguages(
          state.currentInnerLanguage,
          action.payload.currentInnerLanguage
        ),
        currentInnerLanguage: action.payload.currentInnerLanguage,
      };

    default:
      return state;
  }
};

const editKeyOnAnalysisDictionary = (key, value, id, state) => {
  const dictValue = JSON.parse(JSON.stringify(state.analysisDictValues));
  const dictIds = JSON.parse(JSON.stringify(state.analysisDictIds));

  dictValue[state.currentLanguage][key] = value;
  dictIds[state.currentLanguage][key] = id;

  return {
    ...state,
    analysisDictValues: dictValue,
    analysisDictIds: dictIds,
  };
};

const createFullProjectDictionary = (projectDict) => {
  return {
    en: { ...messages.en, ...projectDict.en },
    fr: { ...messages.fr, ...projectDict.fr },
    es: { ...messages.es, ...projectDict.es },
  };
};

const compareLanguages = (prevInnerLanguage, newInnerLanguage) => {
  return prevInnerLanguage !== newInnerLanguage ? true : false;
};

export default LanguageReducer;
