import {
  SHOW_ERROR_MODAL,
  HIDE_MODAL,
  SHOW_SUCCESS_MODAL,
  VALIDATION_ERROR,
} from "../actions/modal.action";

const initialState = {
  show: false,
  messageID: "generic.section.emptytitle",
  titleID: "generic.warning.title",
  titleCode: "generic.warning.title",
  titleClass: "bg-danger",
  extraData: null,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_MODAL:
      return {
        show: true,
        titleID: action.payload.title,
        titleCode: action.payload.code,
        messageID: action.payload.message,
        titleClass: "bg-danger",
        extraData: null,
      };

    case SHOW_SUCCESS_MODAL:
      return {
        show: true,
        titleID: "generic.success.title",
        messageID: action.payload,
        titleClass: "bg-success",
        extraData: null,
      };

    case VALIDATION_ERROR:
      return {
        show: true,
        titleID: "generic.validation.error",
        messageID: action.payload.messageID,
        titleClass: "bg-danger",
        extraData: { ...action.payload },
      };

    case HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default ModalReducer;
