import React from "react";

// REDUX AND ROUTER CONFIGS
import { Provider } from "react-redux";
import store from "./store/store";
import AppRouter from "./routes/AppRouter";
import LanguageProvider from "./components/language/language-provider/LanguageProvider";

const App = () => {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <AppRouter />
      </LanguageProvider>
    </Provider>
  );
};

export default App;
