import { SET_SELECT, REMOVE_SELECT } from "../actions/selectWeigh.action";

let initialState = {
  selectValue: null,
  analysisID: null,
};

const SelectWeighReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECT:
      return {
        selectValue: action.selectValue,
        analysisID: action.analysisID,
      };
    case REMOVE_SELECT:
      return {
        selectValue: null,
        analysisID: null,
      };
    default:
      return state;
  }
};

export default SelectWeighReducer;
