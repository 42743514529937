/* MATRIX */
export const CLEAN_MATRICES = "CLEAN_MATRICES";
export const SET_MATRIX_TO_EDIT = "SET_MATRIX_TO_EDIT";
export const EDIT_PROJECT_MATRIX = "EDIT_PROJECT_MATRIX";

export const setMatrixToEdit = (payload) => {
  return {
    type: SET_MATRIX_TO_EDIT,
    payload,
  };
};

export const editProjectMatrix = (payload) => {
  return {
    type: EDIT_PROJECT_MATRIX,
    payload,
  };
};

export const cleanMatrices = () => {
  return {
    type: CLEAN_MATRICES,
  };
};
