/* CONSEQUENCES */
export const LOAD_MATRIX_CONSEQUENCES = "LOAD_MATRIX_CONSEQUENCES";
export const EDIT_MATRIX_CONSEQUENCE = "EDIT_MATRIX_CONSEQUENCE";
export const SET_MATRIX_CONSEQUENCE_TO_EDIT = "SET_MATRIX_CONSEQUENCE_TO_EDIT";
export const CLEAN_MATRIX_CONSEQUENCES = "CLEAN_MATRIX_CONSEQUENCES";

export const loadMatrixConsequences = (payload) => {
  return {
    type: LOAD_MATRIX_CONSEQUENCES,
    payload,
  };
};

export const editMatrixConsequence = (payload) => {
  return {
    type: EDIT_MATRIX_CONSEQUENCE,
    payload,
  };
};

export const setMatrixConsequenceToEdit = (payload) => {
  return {
    type: SET_MATRIX_CONSEQUENCE_TO_EDIT,
    payload,
  };
};

export const cleanMatrixConsequences = () => {
  return {
    type: CLEAN_MATRIX_CONSEQUENCES,
  };
};
