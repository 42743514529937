import {
  /* MATRICES */
  CLEAN_MATRICES,
  SET_MATRIX_TO_EDIT,
  EDIT_PROJECT_MATRIX,
} from "../actions/matrices.action";

const initialState = {
  currentMatrix: null,
};

const MatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATRIX_TO_EDIT:
      return {
        ...state,
        currentMatrix: action.payload,
      };

    case EDIT_PROJECT_MATRIX:
      return {
        ...state,
        currentMatrix: action.payload,
      };

    case CLEAN_MATRICES:
      return initialState;

    default:
      return state;
  }
};

export default MatrixReducer;
