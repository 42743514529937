import {
  LOAD_PLAYERS,
  CREATE_PLAYER,
  EDIT_PLAYER,
  DELETE_PLAYER,
  CLEAN_PLAYERS,
  LOAD_PLAYER_TO_EDIT,
  SET_PLAYER_TO_EDIT,
} from "../actions/players.action";

const initialState = {
  arePlayersLoaded: false,
  playerList: [],
  currentPlayer: null,
};

const PlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PLAYERS:
      return {
        arePlayersLoaded: true,
        playerList: action.payload,
        currentPlayer: null,
      };

    case CREATE_PLAYER:
      if (!state.arePlayersLoaded) {
        return state;
      }
      const players = state.playerList;
      players.push(action.payload);
      return { ...state, playerList: players };

    case SET_PLAYER_TO_EDIT:
      return {
        ...state,
        currentPlayer: action.payload,
      };

    case EDIT_PLAYER:
      if (!state.arePlayersLoaded) {
        return state;
      }
      return editPlayer(JSON.parse(JSON.stringify(state)), action.payload);

    case LOAD_PLAYER_TO_EDIT:
      if (!state.arePlayersLoaded) {
        return state;
      }
      return loadPlayerToEdit(
        JSON.parse(JSON.stringify(state)),
        action.payload
      );

    case DELETE_PLAYER:
      if (!state.arePlayersLoaded) {
        return state;
      }
      return deletePlayer(JSON.parse(JSON.stringify(state)), action.payload);

    case CLEAN_PLAYERS:
      return initialState;

    default:
      return state;
  }
};

const editPlayer = (state, upatedPlayer) => {
  const players = state.playerList;
  const index = players.findIndex((g) => g.id === upatedPlayer.id);
  players.splice(index, 1, upatedPlayer);
  return { ...state, playerList: players };
};

const deletePlayer = (state, playerId) => {
  const players = state.playerList;
  const index = players.findIndex((g) => g.id === playerId);
  players.splice(index, 1);
  return { ...state, playerList: players };
};

const loadPlayerToEdit = (state, playerId) => {
  const players = state.playerList;
  const selectedPlayer = players.find((g) => g.id === playerId);
  return {
    ...state,
    currentPlayer: selectedPlayer,
  };
};

export default PlayerReducer;
