import conn from "../auth-connector";
import riskConn from "../api-connector";
import jwt from "../../utils/jwt.service";
import endpoints from "../constants/endpoints";
import FormData from "form-data";
import trxIdGenerator from "../../utils/transaction-generator";
import modelProfile from "../models/Profile";
import { languageConnector } from "../language-connector";

const LOCALE = "en-CA";

const createFormDataFromObject = (object) => {
  const form = new FormData();
  for (const key in object) form.append(key, object[key]);
  return form;
};

const refreshToken = async (refresh_token) => {
  const payload = { refresh_token };
  const form = createFormDataFromObject(payload);
  return await conn.post(endpoints.REFRESH_TOKEN, form, getAuthHeaders());
};

const getAuthHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
};

const authorize = async (authdata) => {
  /* Fetch token */
  const payload = { ...authdata, locale: LOCALE, trxId: trxIdGenerator() };
  const form = createFormDataFromObject(payload);
  return await conn.post(endpoints.TOKEN, form, getAuthHeaders());
};

const fetchProfile = async () => {
  /* Fetch user profile information */
  const api_endpoint = endpoints.USER_INFO.replace("{trxId}", trxIdGenerator());
  const data = await conn.get(api_endpoint, jwt.getAuthHeaderToken());
  return modelProfile(data);
};

const getUserRoles = async () => {
  const api_endpoint = endpoints.USERS_ME;
  return await riskConn.get(api_endpoint, jwt.getAuthHeaderToken());
};

const forgotPassword = async (data) => {
  const payload = { ...data, trxId: trxIdGenerator() };
  const api_endpoint = endpoints.FORGOR_PASSWORD;
  return await conn.post(api_endpoint, payload, getAuthHeaders());
};

const resetPassword = async (data) => {
  const payload = { ...data, trxId: trxIdGenerator() };
  const api_endpoint = endpoints.RESET_PASSWORD;
  return await conn.post(api_endpoint, payload, getAuthHeaders());
};

const getLanguagesByUser = async (baseDomain, siteId) => {
  const languageConn = languageConnector(baseDomain);
  const api_endpoint = endpoints.SITE_LANGUAGE.replace("{siteId}", siteId);
  return await languageConn.get(api_endpoint, jwt.getAuthHeaderToken());
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authorize,
  fetchProfile,
  forgotPassword,
  resetPassword,
  refreshToken,
  getUserRoles,
  getLanguagesByUser,
};
