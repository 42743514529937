export const STAGE_DEFAULT_DATA = "STAGE_DEFAULT_DATA";
export const CLEAN_DEFAULT_DATA = "CLEAN_DEFAULT_DATA";

export const stageDefaultData = (payload) => {
  return {
    type: STAGE_DEFAULT_DATA,
    payload,
  };
};

export const cleanDefaultData = () => {
  return {
    type: CLEAN_DEFAULT_DATA,
  };
};
