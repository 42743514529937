import {
  STAGE_DEFAULT_DATA,
  CLEAN_DEFAULT_DATA,
} from "../actions//standby.action";

const initialState = {
  stagedData: null,
};

const StandbyReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAGE_DEFAULT_DATA:
      return {
        ...state,
        stagedData: action.payload,
      };
    case CLEAN_DEFAULT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default StandbyReducer;
