export const LOAD_GROUPS = "LOAD_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const CLEAN_GROUPS = "CLEAN_GROUPS";
export const LOAD_GROUP_TO_EDIT = "LOAD_GROUP_TO_EDIT";
export const SET_GROUP_TO_EDIT = "SET_GROUP_TO_EDIT";

export const loadGroups = (payload) => {
  return {
    type: LOAD_GROUPS,
    payload,
  };
};

export const loadGroupToEdit = (payload) => {
  return {
    type: LOAD_GROUP_TO_EDIT,
    payload,
  };
};

export const setGroupToEdit = (payload) => {
  return {
    type: SET_GROUP_TO_EDIT,
    payload,
  };
};

export const createGroup = (payload) => {
  return {
    type: CREATE_GROUP,
    payload,
  };
};

export const deleteGroup = (payload) => {
  return {
    type: DELETE_GROUP,
    payload,
  };
};

export const editGroup = (payload) => {
  return {
    type: EDIT_GROUP,
    payload,
  };
};

export const cleanGroups = () => {
  return {
    type: CLEAN_GROUPS,
  };
};
