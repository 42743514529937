export const LOAD_BUSINESSES = "LOAD_BUSINESSES";
export const CREATE_BUSINESS = "CREATE_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const EDIT_BUSINESS = "EDIT_BUSINESS";
export const CLEAN_BUSINESSES = "CLEAN_BUSINESSES";
export const LOAD_BUSINESS_TO_EDIT = "LOAD_BUSINESS_TO_EDIT";
export const SET_BUSINESS_TO_EDIT = "SET_BUSINESS_TO_EDIT";

export const loadBusinesses = (payload) => {
  return {
    type: LOAD_BUSINESSES,
    payload,
  };
};

export const loadBusinessToEdit = (payload) => {
  return {
    type: LOAD_BUSINESS_TO_EDIT,
    payload,
  };
};

export const setBusinessToEdit = (payload) => {
  return {
    type: SET_BUSINESS_TO_EDIT,
    payload,
  };
};

export const createBusiness = (payload) => {
  return {
    type: CREATE_BUSINESS,
    payload,
  };
};

export const deleteBusiness = (payload) => {
  return {
    type: DELETE_BUSINESS,
    payload,
  };
};

export const editBusiness = (payload) => {
  return {
    type: EDIT_BUSINESS,
    payload,
  };
};

export const cleanBusinesses = () => {
  return {
    type: CLEAN_BUSINESSES,
  };
};
